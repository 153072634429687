import { store } from '../../src/redux/store'; 
import appConfig from '../appConfig.json';
import { logOutUserWithoutTokenNonAsync } from '../redux/auth/auth.action';
import { showGlobalSnackBar } from '../redux/ui/ui.actions';

const originalFetch = window.fetch;

const UNAUTHORIZED = 401;
window.fetch = async (url, options = {}) => {
    const { dispatch } = store;

    try {
        const response = await originalFetch(url, options);

        if (!response.ok) {
            const status = response.status;
            if (status === UNAUTHORIZED) {
                dispatch(logOutUserWithoutTokenNonAsync()); 
            } 
            // else if (status === 400 && isAuthorizationError) {
            //     const authorizationErrorMsg = responseData.message || 'Authorization error';
            //     dispatch(showGlobalSnackBar({
            //         message: authorizationErrorMsg,
            //         globalSnackBarType: 'error',
            //     }));
            // }
        }

        return response; // Return the original response for further processing
    } catch (error) {
        
    }
};
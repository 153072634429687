export const REFERRAL_MNGMT_ACTION_TYPES = {

    FETCH_COMPANIES_START : 'FETCH_COMPANIES_START',
    FETCH_COMPANIES_SUCCESS : 'FETCH_COMPANIES_SUCCESS',
    CLEAR_COMPANIES_SUCCESS : 'CLEAR_COMPANIES_SUCCESS',
    FETCH_COMPANIES_FAILURE : 'FETCH_COMPANIES_FAILURE',

    FETCH_CLIENT_TYPE_START : 'FETCH_CLIENT_TYPE_START',
    FETCH_CLIENT_TYPE_SUCCESS : 'FETCH_CLIENT_TYPE_SUCCESS',
    FETCH_CLIENT_TYPE_FAILURE : 'FETCH_CLIENT_TYPE_FAILURE',

    LIST_CLIENTS_START : 'LIST_CLIENTS_START',
    LIST_CLIENTS_SUCCESS : 'LIST_CLIENTS_SUCCESS',
    LIST_CLIENTS_FAILURE : 'LIST_CLIENTS_FAILURE',

    ADD_PARALLEL_CLIENT_START : 'ADD_PARALLEL_CLIENT_START',
    ADD_PARALLEL_CLIENT_SUCCESS : 'ADD_PARALLEL_CLIENT_SUCCESS',
    ADD_PARALLEL_CLIENT_FAILURE : 'ADD_PARALLEL_CLIENT_FAILURE',

    ADD_CLIENT_START : 'ADD_CLIENT_START',
    ADD_CLIENT_SUCCESS : 'ADD_CLIENT_SUCCESS',
    ADD_CLIENT_FAILURE : 'ADD_CLIENT_FAILURE',
    CLEAR_ADD_ERROR_DATA : 'CLEAR_ADD_ERROR_DATA',


    FETCH_REFERRER_NAME_START : 'FETCH_REFERRER_NAME_START',
    FETCH_REFERRER_NAME_SUCCESS : 'FETCH_REFERRER_NAME_SUCCESS',
    FETCH_REFERRER_NAME_FAILURE : 'FETCH_REFERRER_NAME_FAILURE',


    EDIT_CLIENT_START : 'EDIT_CLIENT_START',
    EDIT_CLIENT_SUCCESS : 'EDIT_CLIENT_SUCCESS',
    EDIT_CLIENT_FAILURE : 'EDIT_CLIENT_FAILURE',


    FETCH_CLIENT_DETAILS_WITH_ID_START : 'FETCH_CLIENT_DETAILS_WITH_ID_START',
    FETCH_CLIENT_DETAILS_WITH_ID_SUCCESS : 'FETCH_CLIENT_DETAILS_WITH_ID_SUCCESS',
    FETCH_CLIENT_DETAILS_WITH_ID_FAILURE : 'FETCH_CLIENT_DETAILS_WITH_ID_FAILURE',


    FETCH_RECENTLY_ADDED_CLIENTS_START : 'FETCH_RECENTLY_ADDED_CLIENTS_START',
    FETCH_RECENTLY_ADDED_CLIENTS_SUCCESS : 'FETCH_RECENTLY_ADDED_CLIENTS_SUCCESS',
    FETCH_RECENTLY_ADDED_CLIENTS_FAILURE : 'FETCH_RECENTLY_ADDED_CLIENTS_FAILURE',

    
    FETCH_RECENTLY_ADDED_BY_ADVISOR_START : 'FETCH_RECENTLY_ADDED_BY_ADVISOR_START',
    FETCH_RECENTLY_ADDED_BY_ADVISOR_SUCCESS : 'FETCH_RECENTLY_ADDED_BY_ADVISOR_SUCCESS',
    FETCH_RECENTLY_ADDED_BY_ADVISOR_FAILURE : 'FETCH_RECENTLY_ADDED_BY_ADVISOR_FAILURE',


    REMOVE_ERROR_MESSAGE : 'REMOVE_ERROR_MESSAGE',
    REMOVE_SUCCESS_MESSAGE : 'REMOVE_SUCCESS_MESSAGE',


    SEARCH_CLIENTS_START : 'SEARCH_CLIENTS_START',
    SEARCH_CLIENTS_SUCCESS : 'SEARCH_CLIENTS_SUCCESS',
    SEARCH_CLIENTS_FAILURE : 'SEARCH_CLIENTS_FAILURE',

    FETCH_ADVISOR_START : 'FETCH_ADVISOR_START',
    FETCH_ADVISOR_SUCCESS : 'FETCH_ADVISOR_SUCCESS',
    FETCH_ADVISOR_FAILURE : 'FETCH_ADVISOR_FAILURE',



    BULK_ADD_CLIENT_START : 'BULK_ADD_CLIENT_START',
    BULK_ADD_CLIENT_SUCCESS : 'BULK_ADD_CLIENT_SUCCESS',
    BULK_ADD_CLIENT_FAILURE : 'BULK_ADD_CLIENT_FAILURE',


    REFERRAL_MNGMT_ACTION_TYPES: 'REFERRAL_MNGMT_ACTION_TYPES',

    REMOVE_BULK_ADD_SUCCESS_MESSAGE : 'REMOVE_BULK_ADD_SUCCESS_MESSAGE',
    REMOVE_BULK_ADD_ERROR_MESSAGE: 'REMOVE_BULK_ADD_ERROR_MESSAGE',

    SEND_BULK_REFERRAL_EMAIL_START : 'SEND_BULK_REFERRAL_EMAIL_START',
    SEND_BULK_REFERRAL_EMAIL_SUCCESS : 'SEND_BULK_REFERRAL_EMAIL_SUCCESS',
    SEND_BULK_REFERRAL_EMAIL_FAILURE : 'SEND_BULK_REFERRAL_EMAIL_FAILURE',
    REMOVE_SEND_BULK_REFERRAL_EMAIL_SUCCESS_MSG : 'REMOVE_SEND_BULK_REFERRAL_EMAIL_SUCCESS_MSG',
    REMOVE_SEND_BULK_REFERRAL_EMAIL_ERROR_MSG : 'REMOVE_SEND_BULK_REFERRAL_EMAIL_ERROR_MSG',

    GET_CLIENT_PROFILE_SUMMERY_START : 'GET_CLIENT_PROFILE_SUMMERY_START',
    GET_CLIENT_PROFILE_SUMMERY_SUCCESS : 'GET_CLIENT_PROFILE_SUMMERY_SUCCESS',
    GET_CLIENT_PROFILE_SUMMERY_FAILURE : 'GET_CLIENT_PROFILE_SUMMERY_FAILURE',

    CLEAR_REFERRAL_REDUCER_STATE_VALUE : 'CLEAR_REFERRAL_REDUCER_STATE_VALUE'
}
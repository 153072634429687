import React, { useState, useEffect, useCallback, useMemo } from 'react'
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import MailIcon from '@material-ui/icons/Mail';
import davidLogo from '../../../assets/images/david_logo.svg';
import davidLogoSmall from '../../../assets/images/david_logo_small.svg';
import DashboardIcon from '@material-ui/icons/Dashboard';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import Button from '../../Button/Button';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { useLocation } from 'react-router';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import { useDispatch } from 'react-redux';
import { getMailFolderListAsync } from '../../../redux/mail/mail.action';
import { useSelector } from 'react-redux';
import SendIcon from '@material-ui/icons/Send';
import DraftsIcon from '@material-ui/icons/Drafts';
import NoResults from '../../NoResults/NoResults';
import { toLowercaseAndRemoveSpaces, checkPermissions, isNotProduction } from '../../../utils/utils';
import DeleteIcon from '@material-ui/icons/Delete';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import ArchiveIcon from '@material-ui/icons/Archive';
import appConfig from '../../../appConfig';
import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from '@material-ui/icons/Block';
import LabelIcon from '@material-ui/icons/Label';
import OutboxIcon from '../../../assets/icons/outbox_menu_icon.svg';
import moment from 'moment';
import './Sidebar.scss';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import { feeManagement } from '../../../redux/routing/routing.actions';
import { getClientDetails, removeClientData, removeClientVulnerableStatus } from '../../../redux/client/client.actions';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DifferenceIcon from '@mui/icons-material/Difference';
import ArticleIcon from '@mui/icons-material/Article';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import template from "../../../assets/icons/arlIcon/template.svg"
import pendingApproval from "../../../assets/icons/arlIcon/pendingAproval.svg"
import uploadStatement from "../../../assets/icons/arlIcon/uploadStatemend.svg"
import arl from "../../../assets/icons/arlIcon/arl.svg"
import SettingsIcon from '@mui/icons-material/Settings';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import GroupIcon from '@mui/icons-material/Group';
import ProfileManagementSub from '../../ProfileManagementSub/ProfileManagementSub'
// use he same value in header component as margin left if changing this value
const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        [theme.breakpoints.up('1366')]: {  // for 1367 onwards width will be 350px
            width: '320px',
        },
        [theme.breakpoints.up('1920')]: {
            width: '420px',
        },
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.up('1366')]: {  // for 1367 onwards width will be 350px
            width: '320px',
        },
        [theme.breakpoints.up('1920')]: {
            width: '420px',
        },
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(9) + 1,
        [theme.breakpoints.down('769')]: {
            width: 0,
            position: 'relative',
            left: '0',
            display: 'none'
        },
        // for increasing size of small strip on 1920 screens and above
        [theme.breakpoints.up('1919')]: {
            width: theme.spacing(11) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        position: 'relative',
    },
    paper: {
        background: theme.palette.primary.main,
        color: 'white'
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    drawerInner: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        fontSize: '12px',
    }
}));



const mailMenuExpandCollapseHandler = (setIsMobileMenuExpanded) => {
    const innerWidth = window.innerWidth;
    if (innerWidth <= 768) {
        setIsMobileMenuExpanded(false)
    }
}

const sideBarBottomContent = () => {
    return (
        <div className="sidebarBottom">
            <p className="sideBarBottomText">© {moment().format('YYYY')} David, Inc. All rights reserved.</p>
        </div>
    )
}

const Sidebar = ({ isSidebarExpanded, handleDrawerOpen, window, isMobileMenuExpanded, setIsMobileMenuExpanded }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    let location = useLocation();
    const role = useSelector(
        (state) => state.auth.currentUserData.logged_in_user_type
      );
      const isSuperAdmin = role == "SuperAdmin"

    const container = window !== undefined ? () => window().document.body : undefined;
    const isUserLoggedIn = useSelector(state => state.auth.isUserLoggedIn);



    useEffect(() => {
        if (isUserLoggedIn) {
            dispatch(getMailFolderListAsync());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUserLoggedIn])

    const mailFolderList = useSelector(state => state.mail.mailFolderList);
    const mailFolderListLoading = useSelector(state => state.mail.mailFolderListLoading);
    const mailFolderListError = useSelector(state => state.mail.mailFolderListError);
    const inboxUnreadCount = useSelector(state => state.mail.inboxUnreadCount);
    const currentUserData = useSelector(state => state.auth.currentUserData);

    const folderIconSwitcher = useCallback((folderItem, iconClassName = null) => {
        if (mailFolderList) {

            // generating icons for folder items
            if (toLowercaseAndRemoveSpaces(folderItem.folder_name) === toLowercaseAndRemoveSpaces(appConfig.menu_static_names.inbox)) {
                return <AllInboxIcon className={iconClassName} />;
            }
            else if (toLowercaseAndRemoveSpaces(folderItem.folder_name) === toLowercaseAndRemoveSpaces(appConfig.menu_static_names.drafts)) {
                return <DraftsIcon className={iconClassName} />;
            }
            else if (toLowercaseAndRemoveSpaces(folderItem.folder_name) === toLowercaseAndRemoveSpaces(appConfig.menu_static_names.sentItems)) {
                return <SendIcon className={iconClassName} />;
            }
            else if (toLowercaseAndRemoveSpaces(folderItem.folder_name) === toLowercaseAndRemoveSpaces(appConfig.menu_static_names.deletedItems)) {
                return <DeleteIcon className={iconClassName} />;
            }
            else if (toLowercaseAndRemoveSpaces(folderItem.folder_name) === toLowercaseAndRemoveSpaces(appConfig.menu_static_names.spam)) {
                return <NotInterestedIcon className={iconClassName} />;
            }
            else if (toLowercaseAndRemoveSpaces(folderItem.folder_name) === toLowercaseAndRemoveSpaces(appConfig.menu_static_names.archives)) {
                return <ArchiveIcon className={iconClassName} />;
            }
            else if (toLowercaseAndRemoveSpaces(folderItem.folder_name) === toLowercaseAndRemoveSpaces(appConfig.menu_static_names.junk)) {
                return <BlockIcon className={iconClassName} />;
            }
            else if (toLowercaseAndRemoveSpaces(folderItem.folder_name) === toLowercaseAndRemoveSpaces(appConfig.menu_static_names.composeMail)) {
                return <EditIcon className={iconClassName} />;
            }
            else if (toLowercaseAndRemoveSpaces(folderItem.folder_name) === toLowercaseAndRemoveSpaces(appConfig.menu_static_names.outbox)) {
                return (
                    <div className={iconClassName}>
                        <img className="customMenuIcon outboxIcon" src={OutboxIcon} alt="outbox" />
                    </div>
                )
            }
            else {
                return <LabelIcon className={iconClassName} />;
            }
        }
    }, [mailFolderList]);


    //  deciding weather to open submenu or not -- 
    const newPath = `${location.pathname}${location.search}`;
    const [isSidebarItemActive, setIsSidebarItemActive] = useState(false);
    useEffect(() => {
        if (mailFolderList) {
            // mail submenu open close logic -- 
            const fullArrayList = mailFolderList[0]?.fullUrlList;
            if ((fullArrayList && fullArrayList.includes(location.pathname))) {
                setIsSubmenuOpen(true);
                setIsSidebarItemActive(true);
            }
            else {
                setIsSubmenuOpen(false);
                setIsSidebarItemActive(false);
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mailFolderList])



    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
    const submenuHandler = () => {
        setIsSubmenuOpen((prev) => !prev);
    }


    const [isComposePage, setIisComposePage] = useState('');
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const action = params.get('action');
        if (action === 'compose') {
            setIisComposePage('composePage')
        }
        else {
            setIisComposePage('')
        }
    }, [location.search])


    const shouldPrintClass = useCallback(() => {
        return (!isSidebarExpanded && !isMobileMenuExpanded) ? true : false;
    }, [isSidebarExpanded, isMobileMenuExpanded])
    const currentUrl = location?.pathname

    const isProfileSettingsScreen=()=>{
        if(currentUrl === '/profile-management/settings') return true
       return false
    }
    const buttonClasses = `customButton ${isSidebarExpanded ? 'expanded' : ''}`;
    const DrawerContent = useMemo(() => {
        return (
            <List>
                <Button linkTo="/dashboard" buttonType="navlink">
                    {
                        isSidebarExpanded
                            ?
                            <img className={`davidLogo davidLogoNormalSize ${shouldPrintClass() ? 'centerIcons' : null}`} src={davidLogo} alt="david-logo" />
                            :
                            isMobileMenuExpanded
                                ?
                                <img className={`davidLogo davidLogoNormalSize ${shouldPrintClass() ? 'centerIcons' : null}`} src={davidLogo} alt="david-logo" />
                                :
                                <img className={`davidLogo ${shouldPrintClass() ? 'centerIcons' : null} ${isSidebarExpanded ? '' : 'davidLogoSmallSize'}`} src={davidLogoSmall} alt="david-logo-small" />
                    }
                </Button>
                <Button linkTo="/dashboard" buttonType="navlink">
                    <ListItem button sx={{ position: 'relative' }} className={`${shouldPrintClass() ? 'centerIcons' : null}`}>
                        <ListItemIcon className="sideBarIconWrapper">
                            <DashboardIcon className="sideBarIcon" />
                        </ListItemIcon>
                        <ListItemText primary="Dashboard" className="sideBarText" />
                    </ListItem>
                </Button>





                {(currentUserData && checkPermissions(currentUserData.permission_list, ['CLIENT-MANAGEMENT-VIEW'])) &&
                    <div onClick={() => dispatch(feeManagement())}>

                        <Button linkTo="/client-management" buttonType="navlink">
                            <ListItem button className={`${shouldPrintClass() ? 'centerIcons' : null}`}>
                                <ListItemIcon className="sideBarIconWrapper">
                                    <RecentActorsIcon className="sideBarIcon" />
                                </ListItemIcon>
                                <ListItemText primary="Client Management" className="sideBarText" />
                            </ListItem>
                        </Button>
                    </div>
                }

                <Button linkTo="/calendar" buttonType="navlink">
                    <ListItem button className={`${shouldPrintClass() ? 'centerIcons' : null}`}>
                        <ListItemIcon className="sideBarIconWrapper">
                            <CalendarTodayIcon className="sideBarIcon" />
                        </ListItemIcon>
                        <ListItemText primary="Calendar" className="sideBarText" />
                    </ListItem>
                </Button>


                {/* mail section starts  */}

                <ListItem
                    button
                    onClick={submenuHandler}
                    className={`
                    ${shouldPrintClass() ? 'centerIcons' : null}
                    ${(isSidebarItemActive) ? 'activeSubmenuIndicator' : null}
                `}
                >
                    <ListItemIcon className="sideBarIconWrapper">
                        <MailIcon className="sideBarIcon" />
                    </ListItemIcon>
                    <ListItemText primary="Mail" className="sideBarText" />
                    {isSubmenuOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>



                <Collapse in={isSubmenuOpen} timeout="auto" unmountOnExit>
                    <List
                        component="div"
                        disablePadding
                        className={`
                        ${(isSidebarItemActive) ? 'activeSubmenuIndicator' : null}
                    `}
                    >
                        {/* Other mail items starts  */}
                        {
                            mailFolderListLoading
                                ?
                                (<ListItem component="div" className={classes.nested}><p>Loading..</p></ListItem>)
                                :
                                <>
                                    {
                                        mailFolderList && mailFolderList.length > 0 ?
                                            (
                                                mailFolderList.map(folderItem => {
                                                    const folderIcon = folderIconSwitcher(folderItem, 'sideBarIcon');
                                                    const folderName = toLowercaseAndRemoveSpaces(folderItem.folder_name);
                                                    const composeMailClassName = (!isComposePage && folderName === 'composemail') ? 'inboxComposeDifferentiator' : '';
                                                    const inboxPageClassName = (isComposePage && folderName === toLowercaseAndRemoveSpaces(appConfig.menu_static_names.inbox)) ? 'inboxComposeDifferentiator' : '';
                                                    const isComposeMail = toLowercaseAndRemoveSpaces(folderItem.folder_name) === 'composemail' ? true : false;

                                                    return (
                                                        <Button
                                                            key={folderItem.folder_name}
                                                            buttonType="navlink"
                                                            className={`innerMenuItems ${composeMailClassName} ${inboxPageClassName} ${isComposeMail ? 'composeMailLink' : ''}`}
                                                            linkTo={`${folderItem.url}/${folderItem.id}`}

                                                        >
                                                            <ListItem button
                                                                className={`
                                                                    ${shouldPrintClass() ? 'centerIcons' : ''} 
                                                                    ${isComposeMail ? 'composeMailLinkWrapper' : ''}
                                                                `}
                                                                onClick={() => mailMenuExpandCollapseHandler(setIsMobileMenuExpanded)}
                                                            >
                                                                {
                                                                    isComposeMail
                                                                        ?
                                                                        <div className="composeMailItemContainer">
                                                                            <ListItemIcon className="sideBarIconWrapper">
                                                                                {folderIcon}
                                                                            </ListItemIcon>
                                                                            <ListItemText primary={folderItem.folder_name} className="sideBarText" />
                                                                        </div>
                                                                        :
                                                                        <>
                                                                            <ListItemIcon className="sideBarIconWrapper">
                                                                                {folderIcon}
                                                                            </ListItemIcon>
                                                                            <ListItemText primary={folderItem.folder_name} className="sideBarText" />
                                                                            {/* if inbox show unread count */}
                                                                            {
                                                                                folderName === toLowercaseAndRemoveSpaces(appConfig.menu_static_names.inbox) && isSidebarExpanded
                                                                                &&
                                                                                <span className="inboxUnreadCount customDescription">{inboxUnreadCount}</span>
                                                                            }
                                                                        </>
                                                                }

                                                            </ListItem>
                                                        </Button>
                                                    )
                                                })
                                            )
                                            :
                                            <>
                                                {
                                                    mailFolderListError
                                                        ?
                                                        <ListItem component="div" className={classes.nested}>
                                                            <div>
                                                                {mailFolderListError}
                                                            </div>
                                                        </ListItem>
                                                        :
                                                        <ListItem component="div" className={classes.nested}>
                                                            <NoResults widthIcon={false} message="No mail folders." />
                                                        </ListItem>
                                                }
                                            </>

                                    }
                                </>
                        }

                    </List>

                </Collapse>

                {/* mail section ends  */}


                <Button linkTo="/tasks" buttonType="navlink">
                    <ListItem button className={`${shouldPrintClass() ? 'centerIcons' : null}`}>
                        <ListItemIcon className="sideBarIconWrapper">
                            <AssignmentTurnedInIcon className="sideBarIcon" />
                        </ListItemIcon>
                        <ListItemText primary="Task Management" className="sideBarText" />
                    </ListItem>
                </Button>



                <div onClick={() => {
                    dispatch(feeManagement())
                    dispatch(removeClientData())
                    dispatch(removeClientVulnerableStatus())
                }}>
                    <Button linkTo="/feeManagement" buttonType="navlink">
                        <ListItem button className={`${shouldPrintClass() ? 'centerIcons' : null}`}>
                            <ListItemIcon className="sideBarIconWrapper">
                                <CreditScoreIcon className="sideBarIcon" />

                            </ListItemIcon>
                            <ListItemText primary="Fee Management" className="sideBarText" />
                        </ListItem>
                    </Button>
                </div>
                {/* arl moadule */}
                <Button linkTo="/arl" buttonType="navlink">
                    <ListItem button className={`${shouldPrintClass() ? 'centerIcons' : null}`}>
                        <ListItemIcon className="sideBarIconWrapper">
                            <img src={arl} alt="" className="sideBarIcon" />
                            {/* <UploadFileIcon className="sideBarIcon" /> */}
                        </ListItemIcon>

                        <ListItemText primary="Automated Review Letter" className="sideBarText" />
                    </ListItem>
                </Button>


                <Button linkTo="/templates" buttonType="navlink">
                    <ListItem button className={`${shouldPrintClass() ? 'centerIcons' : null}`}>
                        <ListItemIcon className="sideBarIconWrapper">
                            <img src={template} alt="" className="sideBarIcon ml30" />
                            {/* <UploadFileIcon className="sideBarIcon" /> */}
                        </ListItemIcon>

                        <ListItemText primary="Templates" className="sideBarText" />
                    </ListItem>
                </Button>

                <Button linkTo="/pendingApproval" buttonType="navlink">
                    <ListItem button className={`${shouldPrintClass() ? 'centerIcons' : null}`}>
                        <ListItemIcon className="sideBarIconWrapper">
                            {/* <ArticleIcon className="sideBarIcon" /> */}
                            <img src={pendingApproval} alt="" className="sideBarIcon ml30" />
                        </ListItemIcon>

                        <ListItemText primary="Pending Approval" className="sideBarText" />
                    </ListItem>
                </Button>

                <Button linkTo="/uploadStatement" buttonType="navlink">
                    <ListItem button className={`${shouldPrintClass() ? 'centerIcons' : null}`}>
                        <ListItemIcon className="sideBarIconWrapper">
                            <img src={uploadStatement} alt="" className="sideBarIcon ml30" />
                            {/* <DifferenceIcon className="sideBarIcon" />uploadStatement */}
                        </ListItemIcon>

                        <ListItemText primary="Upload Statement" className="sideBarText" />
                    </ListItem>
                </Button>
                {isSuperAdmin || isNotProduction ? (
  <Button linkTo="/profile-management" buttonType="navlink" className={buttonClasses}>
    <ListItem sx={{ position: 'relative' }} button className={`${shouldPrintClass() ? 'centerIcons' : null}`}>
      <ListItemIcon className="sideBarIconWrapper">
        <GroupIcon className="sideBarIcon" />
      </ListItemIcon>
      <ListItemText primary="Profile Management" className="sideBarText" />
      {/* Fix: Add a leading `/` to make it an absolute path */}
      <NavLink
        to={isProfileSettingsScreen() ? "/profile-management/settings" : "/profile-management/settings"}
        style={{ position: 'absolute', top: 10, right: 20 }}
      >
        <SettingsIcon style={{ fontSize: isSidebarExpanded ? 20 : 14, color: '#C5C5C5' }} />
      </NavLink>
    </ListItem>
  </Button>
) : null}

                {isSidebarExpanded && isNotProduction || isSuperAdmin  ? <ProfileManagementSub /> : null}

            </List>


        )
    }, [inboxUnreadCount, isComposePage, isMobileMenuExpanded, isSidebarExpanded, isSidebarItemActive, isSubmenuOpen, mailFolderList])


    return (
        <div id="rootsideBar" className={`sideBar ${isSidebarExpanded ? 'sidebarExpanded' : 'sidebarCollapsed'}`}>
            <Drawer
                variant="permanent"
                className={
                    clsx(classes.drawer, 'desktopMenuSidebar customScrollbar ', {
                        [classes.drawerOpen]: isSidebarExpanded,
                        [classes.drawerClose]: !isSidebarExpanded,
                    })
                }
                classes={{
                    paper: clsx(classes.paper, classes.drawerInner, 'desktopMenuSidebarInner', {
                        [classes.drawerOpen]: isSidebarExpanded,
                        [classes.drawerClose]: !isSidebarExpanded,
                    }),

                }}
            >
                {DrawerContent}
                {isSidebarExpanded && sideBarBottomContent()}

            </Drawer>


            <Drawer
                variant="temporary"
                open={isMobileMenuExpanded}
                anchor="left"
                container={container}
                onClose={handleDrawerOpen}
                className={
                    clsx(classes.drawer, 'mobileMenuSidebar customScrollbar', {
                        [classes.drawerOpen]: isMobileMenuExpanded,
                        [classes.drawerClose]: !isMobileMenuExpanded,
                    })
                }
                classes={{
                    paper: clsx(classes.paper, classes.drawerInner, 'mobileMenuSidebarInner', {
                        [classes.drawerOpen]: isMobileMenuExpanded,
                        [classes.drawerClose]: !isMobileMenuExpanded,
                    }),

                }}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
            >
                {DrawerContent}
                {sideBarBottomContent()}
            </Drawer>
        </div>
    )
}

export default Sidebar;

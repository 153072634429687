import React, { useState, useEffect } from 'react';
import DescriptionIcon from '@material-ui/icons/Description';
import FolderIcon from '@material-ui/icons/Folder';
import './FileBrowser.scss';
import { isObjectEmpty, bytesToSize } from '../../utils/utils';





const FileBrowser = ({fileData, setDriveFiles, setSelectDisabled}) => {


    const [itemHighLighter, setItemHighLighter] = useState([]);
    const [ isPressing, setIsPressing ] = useState(false);
    const rootFolderName = 'Drive';


    // set initial data to view , view is generated based on this state array
    useEffect(() => {

        setItemToSee(fileData?.children)
        setSelectDisabled(true);
        // check if user is holding control key
        function keydownHandler(e) {
            if(e.keyCode===17) {
                setIsPressing(true);
            }
        }


        // check if user has released the pressed key
        function keyupHandler(e)
        {
            setIsPressing(false);
        }


        window.addEventListener('keydown', keydownHandler);
        window.addEventListener('keyup', keyupHandler);
        return () => {
            window.removeEventListener('keydown', keydownHandler);
            window.removeEventListener('keyup', keyupHandler);
        };
    }, [])




    // set base  
    const [breadCrumb, setbreadCrumbs] = useState(
        [
            {
                currentPage: rootFolderName,
                details: [
                    ...fileData?.children
                ]
            }
        ]
    );

    // view is rendered based on data in this state array
    const [itemToSee, setItemToSee] = useState([]);

    return (
        <div className="fileBrowser customScrollbar">
            <ul className="fileBrowserBreadcrumb">
                {
                    breadCrumb.map((crumbItem, i) => {
                        const lastElement = breadCrumb.length - 1;
                        return (
                            <li
                                key={i}
                                onClick={() => {
                                    // remove breadcrumbs on right ride of currently clicked item
                                    breadCrumb.splice(i + 1, breadCrumb.length - (i + 1));

                                    // lets you see the related data of breadcrum
                                    setItemToSee(crumbItem?.details)

                                    // disable select button on navigating back and forth and if user is on last folder dont disable
                                    if( !(i === lastElement))
                                    {
                                        setSelectDisabled(true)
                                    }
 
                                }}
                                className="fileBrowserHeaderText"
                            >
                                {crumbItem?.currentPage}
                            </li>
                        )
                    })
                }
            </ul>
            <div className="fileBrowserHeader">
                <p className="fileBrowserName customDescription">Name</p>
                <p className="fileBrowserSize customDescription">File size</p>
            </div>
            <div className="fileItemsWrapper ">
            {
                itemToSee.map((dataItem, i) => {
                    return (
                        <>
                        {
                            !isObjectEmpty(dataItem) 
                            &&
                        <div
                            className={`fileBrowserItem ${itemHighLighter.includes(dataItem.item_id) ? 'hightLight' : null}`}
                            id={dataItem.item_id}
                            key={dataItem.item_id}
                            onClick={(e) => {
                                if(isPressing && e)
                                {
                                    //  when item trying to add doesnt exists add it
                                    const currentlyClickedItem = dataItem.item_id;
                                    setItemHighLighter(prevState => {
                                        if(!prevState.includes(currentlyClickedItem))
                                        {
                                            return [
                                                ...prevState,
                                                currentlyClickedItem,
                                            ]
                                        }
                                        else
                                        {
                                            const currentData = prevState.filter( prevItem => prevItem !== currentlyClickedItem);
                                            return currentData;
                                        }                                        
                                    });


                                    // set the same files as selected files 
                                    setDriveFiles(prevState => {
                                        const includesArray = prevState.map( prevItem => prevItem.item_id);
                                        if(!includesArray.includes(currentlyClickedItem))
                                        {
                                            return [...prevState,dataItem];
                                        }
                                        else
                                        {
                                            const currentData = prevState.filter( prevItem => {
                                                if(prevItem.item_id !== currentlyClickedItem)
                                                {   
                                                    return prevItem;
                                                }
                                            });
                                            return currentData;
                                        } 
                                        
                                    })
                                }
                                else
                                {
                                    //  when item trying to add already exists remove it
                                    setItemHighLighter(prevState => {
                                        const currentlyClickedItem = dataItem.item_id;
                                        return [
                                            currentlyClickedItem
                                        ]
                                    });

                                    setDriveFiles(prevState => [ dataItem]);
                                }
                            }}
                            onDoubleClick={() => {
                                if (dataItem.item_type === 'folder' && dataItem.children.length > 0)
                                {
                                    // sets breadcrumb data
                                    setbreadCrumbs(prevState => {
                                        return [...prevState, {
                                            currentPage: dataItem.item_name,
                                            details: dataItem.children
                                        }]
                                    })

                                    // changes view when item is clicked
                                    setItemToSee(dataItem.children);

                                    // on moving to another folder nullify currently selected item
                                    setItemHighLighter([]);

                                    setDriveFiles([])
                                }
                            }}
                        >

                                {
                                    !isObjectEmpty(dataItem) 
                                    &&
                                    <>
                                        <div className="fileBrowserItemData">
                                            {
                                            dataItem.item_type === 'file' ? 
                                                <DescriptionIcon className="fileBrowserIcon descriptionIcon"/> 
                                                : 
                                                <FolderIcon className="fileBrowserIcon folderIcon"/>
                                            } 
                                            <p>
                                                {dataItem.item_name}
                                            </p>
                                        </div>
                                        <div className="fileBrowserItemSize">
                                            { dataItem.item_type === 'file' && bytesToSize(dataItem.item_size)}
                                        </div>
                                    </>
                                }
                        </div>
                        
                        
                        
                    }
                    </>
                    )
                })
            }
            </div>
        </div>
    )
}

export default FileBrowser;


import { history } from '../../App';
import { validationErrorFormatter } from '../../utils/utils';
import { AUTH_ACTION_TYPES } from './auth.types';


const INITIAL_STATE = {
    currentUserData: null,
    isUserLoggedIn: false,
    errorData: null,
    successMessage: null,
    isSessionTimeOutModalShown: false,
    isFetching: false,
    logoutSnackBarMessage:null,
    logoutSnackBarType:null,

    notificationCount: 0,
    logoutMessage:false,
    setNotificationCountFetching: false,

    resetPasswordSuccessMsg: null,
    resetPasswordErrorMsg: null,

    

}


const authReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type)
    {
        case AUTH_ACTION_TYPES.SIGN_IN_USER_START:
            return {
                ...state,
                isFetching: true
            }
        case AUTH_ACTION_TYPES.SIGN_IN_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                currentUserData: payload.data,
                notificationCount: payload.data.notification_count, // 
                isUserLoggedIn: payload.data ? true : false
            }
        case AUTH_ACTION_TYPES.SIGN_IN_USER_FAILURE:          
            return {
                ...state,
                errorData: payload.message,
                isFetching: false,
                // this will be true if user is already logged in in some other browsers
                isUserAlreadyLoggedIn: payload?.data?.is_user_already_logged_in,
                // when user is already logged in, from api failure response save token returned by api and use it to log out the user
                previousToken: payload?.data?.token
            }


        // Normal log out case from logout button click

        case AUTH_ACTION_TYPES.LOG_OUT_USER_START:
            return {
                ...state,
                isFetching: true
            }
        case AUTH_ACTION_TYPES.LOG_OUT_USER_SUCCESS:
            setTimeout(function(){
                history.push("/")
                window.location.reload();
            }
            ,30)
            return {
                ...state,
                isFetching: false,
                currentUserData: null,
                isUserLoggedIn: false,
            }
        case AUTH_ACTION_TYPES.ADD_LOGOUT_MESSAGE:
            
            return {
                ...state,
                logoutMessage:true,
                logoutSnackBarMessage:payload.logoutSnackBarMessage,
                logoutSnackBarType:payload.logoutSnackBarType,
            }
        case AUTH_ACTION_TYPES.CLEAR_LOGOUT_MESSAGE:
            
            return {
                ...state,
                logoutMessage:false,
                logoutSnackBarMessage:null,
                logoutSnackBarType:null,
            }
        case AUTH_ACTION_TYPES.LOG_OUT_USER_FAILURE:
            return {
                ...state,
                errorData: payload.message,
                isFetching: false
            }

        // On session expire user needs to be logged out but redirection to login page should only be done on clicking login button in session expire popup thats why this case is written explicitly

        case AUTH_ACTION_TYPES.LOG_OUT_WITH_OUT_LOGIN_PAGE_REDIRECTION_START_START:
            return {
                ...state,
                isFetching: true
            }
        case AUTH_ACTION_TYPES.LOG_OUT_WITH_OUT_LOGIN_PAGE_REDIRECTION_SUCCESS:
            return {
                ...state,
                isFetching: false,
            }
        case AUTH_ACTION_TYPES.LOG_OUT_WITH_OUT_LOGIN_PAGE_REDIRECTION_FAILURE:
            return {
                ...state,
                errorData: payload.message,
                isFetching: false
            }


        case AUTH_ACTION_TYPES.REDIRECT_TO_LOGIN_PAGE:
            return {
                ...state,
                isUserLoggedIn: false,
                currentUserData: null,
            }


        case AUTH_ACTION_TYPES.RESET_PASSWORD_START:
            return {
                ...state,
                isFetching: true
            }
        case AUTH_ACTION_TYPES.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                isFetching: false,
                // successMessage : payload.message,
                resetPasswordSuccessMsg: payload.message
            }
        case AUTH_ACTION_TYPES.RESET_PASSWORD_FAILURE:
            return {
                ...state,
                resetPasswordErrorMsg: payload.message,
                isFetching: false
            }
        case AUTH_ACTION_TYPES.CLEAR_RESET_PASSWORD_ERROR_MSG:
            return {
                ...state,
                resetPasswordErrorMsg: null,
            }
        case AUTH_ACTION_TYPES.CLEAR_RESET_PASSWORD_SUCCESS_MSG:
            return {
                ...state,
                resetPasswordSuccessMsg: null,
            }





        case AUTH_ACTION_TYPES.SEND_FORGOT_PASSWORD_MAIL_START:
            return {
                ...state,
                isFetching: true
            }
        case AUTH_ACTION_TYPES.SEND_FORGOT_PASSWORD_MAIL_SUCCESS:
            return {
                ...state,
                isFetching: false,
                successMessage: payload.message
            }
        case AUTH_ACTION_TYPES.SEND_FORGOT_PASSWORD_MAIL_FAILURE:
            return {
                ...state,
                errorData: payload.message,
                isFetching: false
            }


        // Non Async sections

        case AUTH_ACTION_TYPES.REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                errorData: null
            }
        case AUTH_ACTION_TYPES.REMOVE_SUCCESS_MESSAGE:
            return {
                ...state,
                successMessage: null
            }

        case AUTH_ACTION_TYPES.SHOW_SESSION_TIMEOUT_MODAL:
            return {
                ...state,
                isSessionTimeOutModalShown: true
            }
        case AUTH_ACTION_TYPES.HIDE_SESSION_TIMEOUT_MODAL:
            return {
                ...state,
                isSessionTimeOutModalShown: false,
            }

        case AUTH_ACTION_TYPES.SET_ISFETCHING_TO_FALSE:
            return {
                ...state,
                isFetching: false
            }

        case AUTH_ACTION_TYPES.SET_IS_USER_ALREADY_LOGGEDIN_TO_FALSE:
            return {
                ...state,
                isUserAlreadyLoggedIn: false,
                previousToken: null
            }





        case AUTH_ACTION_TYPES.SET_NOTIFICATION_COUNT_START:
            return {
                ...state,
                setNotificationCountFetching: true,
            }
        case AUTH_ACTION_TYPES.SET_NOTIFICATION_COUNT_SUCCESS:
            return {
                ...state,
                setNotificationCountFetching: false,
                notificationCount: payload.data.notification_count
            }
        case AUTH_ACTION_TYPES.SET_NOTIFICATION_COUNT_FAILURE:
            return {
                ...state,
                setNotificationCountFetching: false,
                errorData: payload.message
            }
        case AUTH_ACTION_TYPES.CLEAR_NOTIFICATION_COUNT:
            return {
                ...state,
                notificationCount: payload

            }









        default:
            return state;
    }
}


export default authReducer;





export const AUTH_ACTION_TYPES = {
    SIGN_IN_USER_SUCCESS : 'SIGN_IN_USER_SUCCESS',
    SIGN_IN_USER_FAILURE : 'SIGN_IN_USER_FAILURE', // wont be used
    SIGN_IN_USER_START : 'SIGN_IN_USER_START',

    LOG_OUT_USER_SUCCESS : 'LOG_OUT_USER_SUCCESS',
    LOG_OUT_USER_FAILURE : 'LOG_OUT_USER_FAILURE', 
    LOG_OUT_USER_START : 'LOG_OUT_USER_START',

    LOG_OUT_WITH_OUT_LOGIN_PAGE_REDIRECTION_START : 'LOG_OUT_WITH_OUT_LOGIN_PAGE_REDIRECTION_START',
    LOG_OUT_WITH_OUT_LOGIN_PAGE_REDIRECTION_SUCCESS : 'LOG_OUT_WITH_OUT_LOGIN_PAGE_REDIRECTION_SUCCESS',
    LOG_OUT_WITH_OUT_LOGIN_PAGE_REDIRECTION_FAILURE : 'LOG_OUT_WITH_OUT_LOGIN_PAGE_REDIRECTION_FAILURE',
    CLEAR_LOGOUT_MESSAGE:'CLEAR_LOGOUT_MESSAGE',
    ADD_LOGOUT_MESSAGE:'ADD_LOGOUT_MESSAGE',
    REDIRECT_TO_LOGIN_PAGE: 'REDIRECT_TO_LOGIN_PAGE',

    RESET_PASSWORD_START : 'RESET_PASSWORD_START',
    RESET_PASSWORD_SUCCESS : 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE : 'RESET_PASSWORD_FAILURE',
    CLEAR_RESET_PASSWORD_ERROR_MSG : 'CLEAR_RESET_PASSWORD_ERROR_MSG',
    CLEAR_RESET_PASSWORD_SUCCESS_MSG : 'CLEAR_RESET_PASSWORD_SUCCESS_MSG',

    SEND_FORGOT_PASSWORD_MAIL_START: 'SEND_FORGOT_PASSWORD_MAIL_START',
    SEND_FORGOT_PASSWORD_MAIL_SUCCESS: 'SEND_FORGOT_PASSWORD_MAIL_SUCCESS',
    SEND_FORGOT_PASSWORD_MAIL_FAILURE: 'SEND_FORGOT_PASSWORD_MAIL_FAILURE',

    REMOVE_ERROR_MESSAGE : 'REMOVE_ERROR_MESSAGE',
    REMOVE_SUCCESS_MESSAGE : 'REMOVE_SUCCESS_MESSAGE',

    SHOW_SESSION_TIMEOUT_MODAL : 'SHOW_SESSION_TIMEOUT_MODAL',
    HIDE_SESSION_TIMEOUT_MODAL : 'HIDE_SESSION_TIMEOUT_MODAL',

    SET_ISFETCHING_TO_FALSE : 'SET_ISFETCHING_TO_FALSE',
    SET_IS_USER_ALREADY_LOGGEDIN_TO_FALSE : 'SET_IS_USER_ALREADY_LOGGEDIN_TO_FALSE',

    DESTROY_SESSION: 'DESTROY_SESSION',


    SET_NOTIFICATION_COUNT_START : 'SET_NOTIFICATION_COUNT_START',
    SET_NOTIFICATION_COUNT_SUCCESS : 'SET_NOTIFICATION_COUNT_SUCCESS',
    SET_NOTIFICATION_COUNT_FAILURE : 'SET_NOTIFICATION_COUNT_FAILURE',
    CLEAR_NOTIFICATION_COUNT : 'CLEAR_NOTIFICATION_COUNT',



    
}   
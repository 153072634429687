import { AUTH_ACTION_TYPES } from './auth.types';
import {
    signInUserService,
    logOutUserService,
    resetPasswordService,
    sendForgotPasswordMailService,
    logOutUserWithoutTokenService,
    logOutWithOutLoginPageRedirectionService,
    setNotificationCountService,
    fetchProfileSettingsService,
    editProfileSettingsService
} from '../../services/authService';


export const removeErrorMessage = () => ({
    type: AUTH_ACTION_TYPES.REMOVE_ERROR_MESSAGE,
})

export const removeSuccessMessage = () => ({
    type: AUTH_ACTION_TYPES.REMOVE_SUCCESS_MESSAGE,
})


export const signInUserAsync = (singInData) => {
    return dispatch => {
        dispatch({ type: AUTH_ACTION_TYPES.DESTROY_SESSION }); // for clearing all reducers
        signInUserService(dispatch, singInData)
    }
}




export const logOutUserAsync = () => {
    return dispatch => {
        logOutUserService(dispatch);
    }
}


export const logOutWithOutLoginPageRedirectionAsync = () => {
    return dispatch => {
        logOutWithOutLoginPageRedirectionService(dispatch)
    }
}
export const redirectToLoginPage = () => ({
    type: AUTH_ACTION_TYPES.REDIRECT_TO_LOGIN_PAGE,
})



// --- Starts  : Multiple browser login case commented -- 
/* 
    logOutUserWithoutToken is only for managing extra error cases on multiple browsers
*/
// export const logOutUserWithoutToken = () => {
//     return dispatch => {
//         logOutUserWithoutTokenService(dispatch);
//     }
// }
// --- Ends  : Multiple browser login case commented -- 



export const resetPasswordAsync = (resetPasswordData) => {
    return dispatch => {
        resetPasswordService(dispatch, resetPasswordData);
    }
}

export const sendForgotPasswordMailAsync = (userData) => {
    return dispatch => {
        sendForgotPasswordMailService(dispatch, userData);
    }
}



export const showSessionTimeOutModal = () => ({
    type: AUTH_ACTION_TYPES.SHOW_SESSION_TIMEOUT_MODAL
})


export const hideSessionTimeOutModal = () => ({
    type: AUTH_ACTION_TYPES.HIDE_SESSION_TIMEOUT_MODAL
})


export const setIsFetchingFalse = () => ({
    type: AUTH_ACTION_TYPES.SET_ISFETCHING_TO_FALSE
})



export const setIsUserAlreadyLoginToFalse = () => ({
    type: AUTH_ACTION_TYPES.SET_IS_USER_ALREADY_LOGGEDIN_TO_FALSE
})


export const addLogoutMessage = (snackbarDetails) => ({
    type: AUTH_ACTION_TYPES.ADD_LOGOUT_MESSAGE,
    payload:snackbarDetails
})

export const logOutUserWithoutTokenNonAsync = () => {
    return dispatch => {
        logOutUserWithoutTokenService(dispatch)
    }
}



export const setNotificationCountAsync = () => {
    return dispatch => {
        setNotificationCountService(dispatch)
    }
}

export const clearNotificationCount = (count) => ({
    type: AUTH_ACTION_TYPES.CLEAR_NOTIFICATION_COUNT,
    payload : count
})
export const clearLogoutMessage = (count) => ({
    type: AUTH_ACTION_TYPES.CLEAR_LOGOUT_MESSAGE,
})

export const clearResetPasswordSuccessMsg = () => ({
    type: AUTH_ACTION_TYPES.CLEAR_RESET_PASSWORD_SUCCESS_MSG,
})
export const clearResetPasswordErrorMessage = () => ({
    type: AUTH_ACTION_TYPES.CLEAR_RESET_PASSWORD_ERROR_MSG,
})





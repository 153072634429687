import React, { useEffect, useMemo, useRef, useState } from 'react'
import './DashboardContent.scss'

import DashbaordMailView from '../Dashboard/DasboardMailView/DashboardMailView';
import Modal from '../Modal/Modal';
import { axiosGet } from '../../utils/api';
import { debounce, isNOTNullOrUndefined, truncateString } from '../../utils/utils';
import { useLocation } from 'react-router-dom';
import { Treemap, Tooltip, ResponsiveContainer } from "recharts";
import { CustomTreeComponent } from "./CustomTreeComponent";
import AddandEditEventModal from '../Calendar/AddEditModal/AddEditModal';
import '../Calendar/CalendarMain/CalendarMain.scss';
import moment from 'moment';
import RenderTooltip from "./RenderTooltip";
import Grid from '@material-ui/core/Grid';
import DialogContent from "@material-ui/core/DialogContent";
import Button from "../../components/Button/Button";
import DialogActions from "@material-ui/core/DialogActions";
import usePermissionCheck from '../../hooks/usePermissionCheck';
import CircularLoader from '../CircularLoader/CircularLoader';
import { useDispatch, useSelector } from 'react-redux';
import { isNullOrUndefined } from '@syncfusion/ej2-base';
import { fetchSocialMediums, setCurrentClientType } from '../../redux/dashboard/dashboard.actions';
import { FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Slider, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { fetchEventDataAsync, markParticipationAsync, removeEventData, deleteOrCancelEvent, fetchEventListAsync, clearAddEditEventSuccessMessage, clearEditEventErrorMessage, clearAddEventErrorMessage } from '../../redux/calendar/calendar.actions';
import { setFilesSelectedFromAttachmentModal, removeIndivigualFileItems } from '../../redux/ui/ui.actions';
import { useHistory } from "react-router-dom";
import { GenerateData } from './data';
import SelectFieldReact from '../SelectField/SelectFieldReact';
import appConfig from '../../appConfig.json';


const clientMailvalue = {
    client_mail: ['shamil@techversantinfotech.com'],
    mail_cc: ['saamil@gmail.com', 'azasda@gmail.com'],
    mail_bcc: ['samil@gmail.com', 'afasfa@gmail.com'],
    subject: 'hey there new subject',
    content: 'dasdas asd asda '
}

const sortOptions = [
    '87FFB9',
    '42C77A',
    '328956',
    'FFA498',
    'D91E1E',
]

const filterOptions = [
    { id: 'all', name: "All Clients" },
    { id: 'Fidelity', name: 'Fidelity' },
    { id: 'Non_Fidelity', name: 'Non Fidelity' },
    { id: 'Fidelity_plus_Non_Fidelity', name: 'Fidelity + Non Fidelity' },
]
const ProfileMangementContent = () => {
    const dispatch = useDispatch()
    const is_fetching_socials = useSelector(state => state.dashboard.is_Fetching_social_mediums);
    const social_mediums = useSelector(state => state.dashboard.social_medium_data);
    const currentUserData = useSelector(state => state.auth.currentUserData);
    const clientDetails = useSelector(state => state.client?.clientDetails);
    const eventData = useSelector(state => state.calendar.eventData);
    const addEventData = useSelector(state => state.calendar.addEventData);
    const newEditData = useSelector(state => state.calendar.newEditData);
    const addEventSuccessMessage = useSelector(state => state.calendar.addEventSuccessMessage);
    const editEventSuccessMessage = useSelector(state => state.calendar.editEventSuccessMessage);
    const editErrorMessage = useSelector(state => state.calendar.editErrorMessage);
    const addEventErrorMessage = useSelector(state => state.calendar.addEventErrorMessage);
    const [showMailModal, setShowMailModal] = useState(false)
    const [next, setnext] = useState(true)
    const [deleteProvider,setDeleteProvider] = useState(false)
    const [sortColor, setSortColor] = useState('')
    const [sortOrder, setSortOrder] = useState('')
    const [loading, setLoading] = useState(false);
    const [isAddEVentModalOpen, setIsAddEventModalOpen] = useState({ status: false, isOpenForEditing: false });
    const [data, setData] = useState([])
    const [meetingSchedule, setMeetingSchedule] = useState({})
    const [redirectRequired, setRedirectRequired] = useState(false);
    const [offsetValue, setOffsetValue] = useState(0)
    const [openEventId, setOpenEventId] = useState(null)
    const [selectedCalendarInfo, setSelectedCalendarInfo] = useState(null);
    const [itemRemoved, setItemRemoved] = useState('');
    const [isScheduleMeeting, setIsScheduleMeeting] = useState(false)
    const [modalClosed, setModalClosed] = useState(false);
    const [openEmailErrorModal, setOpenEmailErrorModal] = useState(false);
    const [errorEmailList, setErrorEmailList] = useState([]);
    const [selectedCient, setSelectedClient] = useState(null)
    const [selectedFilter, setSelectedFilter] = useState('all')
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation()
    const client_type_id = location.state
    if (isNullOrUndefined(client_type_id)) {
        dispatch(setCurrentClientType(''))
    }
    const controller = new AbortController();
    const history = useHistory();


    const calendarRef = useRef();

 
    useEffect(() => {
        return (() => {
            dispatch(removeEventData())
        })
    }, [])



    const setErrorToast = (errMessage) => {

        enqueueSnackbar(errMessage, {
            variant: 'error',
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
            },
        });
    }
    useEffect(()=>{
        if(addEventSuccessMessage)
        {
                enqueueSnackbar(addEventSuccessMessage, {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                    onClose: () => {
                        dispatch(clearAddEditEventSuccessMessage())
                    }
                });
        }

        else if(editEventSuccessMessage)
        {
                enqueueSnackbar(editEventSuccessMessage, {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                    onClose: () => {
                        dispatch(clearAddEditEventSuccessMessage())
                    }
                });
        }
        else if(addEventErrorMessage)
        {
                enqueueSnackbar(addEventErrorMessage, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                    onClose: () => {
                        dispatch(clearAddEventErrorMessage())
                    }
                }); 
        }
        else if(editErrorMessage)
        {
                enqueueSnackbar(editErrorMessage, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                    onClose: () => {
                        dispatch(clearEditEventErrorMessage())
                    }
                });
        }
        setIsAddEventModalOpen({ status: false, isOpenForEditing: false });
    },[addEventSuccessMessage, editEventSuccessMessage,addEventErrorMessage,editErrorMessage])
    useEffect(()=>{
        if(location.state)
        {
            const { jumpToEventId, isNewMeeting, clientEmail, eventTitle, taskId, is_draft_meeting } = location.state;
            if(jumpToEventId || isNewMeeting == true){
                setRedirectRequired(true)
            }

            if(isNewMeeting)
            {
                setIsAddEventModalOpen({ status: true, isOpenForEditing: false });
                let attendeesList = [];
                const timestamp = new Date().getUTCMilliseconds();
                attendeesList.push({"id":timestamp, "searchRecipientName":clientEmail})
                setMeetingSchedule({
                    "subject":eventTitle,
                    "attendees":attendeesList,
                    "attachment_list":[],
                    "user":currentUserData.id,
                    "client_id":clientDetails.id,
                    "advisor_id":currentUserData.id,
                    "task_id":taskId ? taskId : null,
                    "is_draft_meeting": is_draft_meeting ? is_draft_meeting : null
                })
                setIsScheduleMeeting(isNewMeeting)
            }
            else
            {
                if(jumpToEventId){
                    dispatch(fetchEventDataAsync(jumpToEventId))
                    setOpenEventId(jumpToEventId)
                }
            }
        }
    },[location])

    useEffect(() => {
        dispatch(removeIndivigualFileItems([]))
        if (isAddEVentModalOpen.isOpenForEditing)
        {
            dispatch(setFilesSelectedFromAttachmentModal([...eventData.attachment_list]))
        }
    }, [isAddEVentModalOpen])

    const handleClose = () => {
        setIsAddEventModalOpen({ status: false, isOpenForEditing: false });
        dispatch(removeIndivigualFileItems([]));
        setModalClosed(true);
        // setIsScheduleMeeting(false)
    };

    useEffect(() => {
        if (selectedCalendarInfo !== null)
        {
            setIsAddEventModalOpen({ status: true, isOpenForEditing: false });
        }
    }, [selectedCalendarInfo])

   

    const openEmailErrorModalHandler = (trueOrFalse) => {
        setOpenEmailErrorModal(trueOrFalse);
    }

    useEffect(() => {
        setData([])
        setOffsetValue(0)
        fetchClientsData(0)
        dispatch(fetchSocialMediums())
    }, [sortColor, client_type_id, sortOrder, selectedFilter])
    const abortFetch = () => {
        controller.abort(); // Abort the ongoing request
    };
    const getColorCode = useMemo(() => {
        return (colorCode) => {
            let regx = /#/g;
            return regx.test(colorCode) ? colorCode : '#' + colorCode;
        };
    }, []);

    const getTextColor = (backgroundColor) => {
        // Convert the hex color to RGB
        const r = parseInt(backgroundColor.slice(0, 2), 16);
        const g = parseInt(backgroundColor.slice(2, 4), 16);
        const b = parseInt(backgroundColor.slice(4, 6), 16);
    
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;
      // Choose black or white based on the brightness
        return brightness > 128 ? 'black' : 'white';
    };
    async function fetchClientsData(currentOffset) {
        setLoading(true);
        const url = `dashboard-metrix/?ordering=-ratio${isNOTNullOrUndefined(client_type_id) ? `&client_type=${client_type_id}` : ''}${isNOTNullOrUndefined(sortColor) ? `&color_code=${sortColor}` : ''}${isNOTNullOrUndefined(selectedFilter) && selectedFilter != 'all' ? `&instrument=${selectedFilter}` : ''}`;
        try {
            const response = await axiosGet(url, { signal: controller.signal });
            if (response.data.data) {
                const formattedData = response.data.data.map(i => ({
                    name: `${i.client.user.first_name} ${i.client.user.last_name}`,
                    size: i.ratio,
                    color: getColorCode(i.current_color),
                    last_email_sent: i.last_email_sent,
                    email: i.client.user.email,
                    client_id: i.client.id,
                    aum: formatAum(i.client.aum),
                    socials: i.client.social_mediums,
                    relative_client_name: i.client.relative_client_name,
                    first_name: i.client.user.first_name,
                    last_name: i.client.user.last_name,
                    advisor: i.client.advisor
                }));
                setData(formattedData);
            } else {
                setData([]);
            }
        } catch (error) {
            if (error.name == 'AbortError') {
                enqueueSnackbar("Previous request cancel", {
                    variant: "error",
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center'
                    }
                  })
            } else {
                enqueueSnackbar("Error to fatching data", {
                    variant: "error",
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center'
                    }
                  })
            }
        } finally {
            setLoading(false);
        }
    }
    const formatAum = (aumValue) => {
        if (aumValue < 1000000) {
            return `${(aumValue / 1000).toFixed(0)}k`;
        } else {
            return `${(aumValue / 1000000).toFixed(1)}m`;
        }
    };

    const clearAllFilters = () => {
        //dispatch(setCurrentClientType(''))
     //   setSortOrder('')
        setSortColor('')
        // setSelectedFilter('all')
        // history.replace({ ...history.location, state: undefined });
    }



    if (is_fetching_socials) return <div className='loader'> <CircularLoader className="pageCirclularLoader" /></div>
    return (
        <div className='content-body'>
            <div className="top-bar">
                <p className="title">AUM figures</p>
                <div style={{ width: '1px', height: '100%', background: '#848484' }} />
                <p className="text-style">Sort by :</p>
                {sortOptions.map((i, index) => <div onClick={() => setSortColor(i)} key={index} className='sort-btn' style={{ background: getColorCode(i) ,  color: getTextColor(i)}} >{index + 1}</div>)}
                {/* <div className='sort-btn-2' onClick={() => setSortOrder('client__user__first_name')} >
                    A-Z
                </div>
                <div className='sort-btn-2' onClick={() => setSortOrder('-client__user__first_name')}  >
                    Z-A
                </div> */}
              {!loading ?    <div style={{ width: "auto" }}>
                    <SelectFieldReact options={filterOptions.map(i => {
                        return { value: i.id, label: i.name }
                    })} value={selectedFilter} onChange={(e) => {
                        setSelectedFilter(e.value)
                    }} />
                </div>: null}
                {
                // isNOTNullOrUndefined(client_type_id) || isNOTNullOrUndefined(sortOrder) || 
                isNOTNullOrUndefined(sortColor) 
                // || selectedFilter != 'all'
                 ? <div className='clear-btn' onClick={() => clearAllFilters()}>
                    Clear Filters
                </div> : null}
            </div>
            <div style={{ width: '100%', height: '75vh', overflowY: 'hidden', overflowX: 'hidden', border: '1px solid #000', borderRadius: '2px' }}
            >
                <div
                    className="tree-container" style={{ width: '100%', height: `75vh`, minHeight: '75vh', background: '#fff' }}>

                    {data?.length > 0 ? <ResponsiveContainer width="100%" height="100%">
                        <Treemap
                            data={data}
                            dataKey="size"
                            stroke="#fff"
                            fill="orange"
                            content={<CustomTreeComponent onTileClick={(i) => {
                                setSelectedClient(i)
                                setDeleteProvider(true)
                            }} />}
                            isAnimationActive={false}
                            aspectRatio={1 / 1}
                        >
                            <Tooltip content={RenderTooltip} />
                        </Treemap>
                    </ResponsiveContainer> : !loading ? <div className='loader'><p>No data</p> </div> : null}
                    {loading ? <div className='loader'> <CircularLoader className="pageCirclularLoader" /></div> : null}

                </div>
            </div>
            <Modal
        open={deleteProvider}
        handleClose={() => setDeleteProvider(false)}
        size="sm"
        className="deleteModal"
      >
        <DialogContent>
          <h2 className="snoozeHead customHead" style={{ textAlign: 'center' }}>
          {selectedCient && selectedCient.first_name && selectedCient.last_name
        ? `${selectedCient.first_name} ${selectedCient.last_name}`
        : "Client Name"}
          </h2>
          <div className="snoozeSubHeadWrapper">
            <h2 className="snoozeSubHead discardMessage customDescription">{" "}</h2>
            <hr />
            <div className="snoozeChangeSelectWrapper">
              <Grid container spacing={2}>
                <Grid item xs={12} className="deleteMessage" style={{ textAlign: 'center',fontSize: '1rem' }}>
                 Do you want to catch up or schedule a meeting with this client?
                 
                </Grid>
              </Grid>
            </div>
          </div>
          <DialogActions className="modalFooter"> 
          <Button
              className="deleteBtn"
              variant="contained"
              size="small"
              bgColor="secondary"
              onClick={() => setShowMailModal(true)}
            
            >
              Catch up mail
            </Button> 
          <Button
              className="deleteBtn"
              variant="contained"
              size="small"
              bgColor="secondary"
              onClick={() => {
                setIsAddEventModalOpen({ status: true, isOpenForEditing: false });

                let attendeesList = [];
                const timestamp = new Date().getUTCMilliseconds();
                attendeesList.push({ id: timestamp, searchRecipientName: selectedCient?.email });
                const { taskId, is_draft_meeting } = location.state || {};

                // Set the meeting schedule state with the selected client details
                setMeetingSchedule({
                  subject: [],
                  attendees: attendeesList,
                  attachment_list: [],
                  user: currentUserData?.id,
                  client_id: selectedCient?.client_id,
                  advisor_id: currentUserData?.id,
                  task_id: taskId ? taskId : null,
                  is_draft_meeting: is_draft_meeting ? is_draft_meeting : null
                });
            
                // Ensure modal opens for scheduling a meeting
                setIsScheduleMeeting(true);

            }}
            
            >
              Schedule meeting
            </Button>
            <Button
              className="cancelBtn"
              variant="contained"
              size="small"
              bgColor="success"
              onClick={() => setDeleteProvider(false)}
            >
              Cancel
            </Button>
          
          </DialogActions>
        </DialogContent>
        <AddandEditEventModal
                isAddEVentModalOpen={isAddEVentModalOpen}
                setIsAddEventModalOpen={setIsAddEventModalOpen}
                eventData={isScheduleMeeting ? meetingSchedule : eventData}
                selectedCalendarInfo={selectedCalendarInfo}
                handleClose={handleClose}
              //  calendarRef={calendarRef}
                openEmailErrorModalHandler={openEmailErrorModalHandler}
                setErrorEmailList={setErrorEmailList}
            />
      </Modal>
            <Modal open={showMailModal} handleClose={() => setShowMailModal(false)} >
                <DashbaordMailView
                    afterSuccessfullySent={() => {
                        setShowMailModal(false)
                        fetchClientsData()
                    }}
                    client_details={selectedCient} close={() => setShowMailModal(false)} className="mailModal" clientMailvalue={clientMailvalue} />
            </Modal>
        </div>
    )
}

export default ProfileMangementContent
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';



const PrivateRoute = ({ component: Component, ...rest }) => {

   const isUserLoggedIn = useSelector(state => state.auth.isUserLoggedIn);
    return (
        <Route
            {...rest}
            render={(props) => isUserLoggedIn ? <Component {...props} /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />}
        />
    )
}

export default PrivateRoute;

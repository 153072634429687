import React, { Suspense, useEffect } from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import './App.scss'
import './utils/fetchApiSetup'
import { useSelector, useDispatch } from 'react-redux'
import PrivateRoute from './components/PrivateRoute/PrivateRoute'
import PageLoader from './components/PageLoader/PageLoader'
import PageNotFound from './components/PageNotFound/PageNotFound'
import { closeGlobalSnackBar } from './redux/ui/ui.actions'
import { useSnackbar } from 'notistack'
import { removeMailSuccessMessage } from './redux/mail/mail.action'
import {
  LoginPage,
  ForgotPasswordPage,
  ResetPasswordForm,
  ComposeMailPage,
  MailPage,
  DashboardPage,
  Calendar,
  ReferralManagementPage,
  AddClientPage,
  ClientListPage,
  EditClientPage,
  ClientProfilePage,
  InstrumentPage,
  ProductsListPage,
  ProductsSummaryPage,
  ChecklistPage,
  TaskListPage,
  ARLPage,
  ARLMainPage,
  TaskDetailsPage,
  ProfileSettingsPage,
  SurveyFormPage,
  RemindersPage,
  DraftRecommendationReportPage,
  DraftRecommendationSummaryPage,
  FeeReconciliationPage,
  ConfirmClientPage,
  ExtractedDataPage,
  FeeManagement,
  FeematchingBrudcrum,
  FeesplitBrudcrum,
  ReportBrudcrum,
  SelectClientBrudcrum,
  FeePendingIssue,
  FeeMonthIssuedPage,
  uploadStatementPage,
  Templates,
  DashboardSettingsPage
} from './routers/lazyLoading'
import { setIsFetchingFalse } from './redux/auth/auth.action'

import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'
import SelectClient from './components/FeeManagement/CreateFeeExpectation/SelectClient'
import ProfileManagementDashboard from './pages/ProfileManagement/ProfileManagementDashboard'
import DraftRecommendationSummaryTaskListPage from './pages/DraftRecommendationSummaryTaskListPage/DraftRecommendationSummaryTaskListPage'

export const history = createBrowserHistory()
const App = () => {
  const { enqueueSnackbar } = useSnackbar()
  const isGlobalSnackBarShown = useSelector(
    state => state.ui.isGlobalSnackBarShown
  )
  const globalSnackBarMessage = useSelector(
    state => state.ui.globalSnackBarMessage
  )
  const globalSnackBarType = useSelector(state => state.ui.globalSnackBarType)
  const dispatch = useDispatch()
  const isUserLoggedIn = useSelector(state => state.auth.isUserLoggedIn)
  const authToken = useSelector(state => state.auth?.currentUserData?.token)

  useEffect(() => {
    dispatch(setIsFetchingFalse())
  }, [])
  const composeMailData = useSelector(state => state.mail.composeMailData)

  useEffect(() => {
    if (composeMailData) {
      enqueueSnackbar(composeMailData, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        },
        onClose: () => {
          dispatch(removeMailSuccessMessage())
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [composeMailData])

  useEffect(() => {
    if (isGlobalSnackBarShown) {
      enqueueSnackbar(globalSnackBarMessage, {
        variant: globalSnackBarType,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        },
        onClose: () => {
          dispatch(closeGlobalSnackBar())
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGlobalSnackBarShown])

  const route = useSelector(state => state.routingReducer)

  return (
    <Router history={history}>
      {/* <ErrorBoundary token={authToken}> */}
      <Suspense fallback={<PageLoader />}>
        <Switch>
          {/*This will prevent showing login page once logged in */}
          <Route
            exact
            path='/'
            render={() =>
              isUserLoggedIn ? <Redirect to='/client-management' /> : <LoginPage />
            }
          />

          <Route exact path='/forgotpassword' component={ForgotPasswordPage} />
          <Route exact path='/dashboard' component={DashboardPage} />
          <PrivateRoute
            exact
            path='/reset-password'
            component={ResetPasswordForm}
          />
          <PrivateRoute
            exact
            path='/profile-settings'
            component={ProfileSettingsPage}
          />

          {/* Mail module section */}
          <PrivateRoute
            exact
            path='/mail/compose-mail'
            component={ComposeMailPage}
          />
          <PrivateRoute
            exact
            path='/mail/:folderName/:folderId'
            component={MailPage}
          />
          <PrivateRoute exact path='/calendar' component={Calendar} />
          <PrivateRoute
            exact
            path='/uploadStatement'
            component={uploadStatementPage}
          />

          <PrivateRoute exact path='/feeManagement' component={FeeManagement} />
          <PrivateRoute
            exact
            path='/feeManagement/feeMatching'
            component={FeematchingBrudcrum}
          />
          <PrivateRoute
            exact
            path='/feeManagement/feeSplit'
            component={FeesplitBrudcrum}
          />
          <PrivateRoute
            exact
            path='/feeManagement/report'
            component={ReportBrudcrum}
          />
          <PrivateRoute
            exact
            path='/feeManagement/SelectClient'
            component={SelectClientBrudcrum}
          />

          <PrivateRoute
            exact
            path='/feeManagement/pendingIssue'
            component={FeePendingIssue}
          />
          <PrivateRoute
            exact
            path='/feeManagement/monthIssue'
            component={FeeMonthIssuedPage}
          />
          {/* <PrivateRoute exact path="/MonthlyIssued" component={MonthlyIssued} />
						<PrivateRoute exact path="/selectClient" component={selectClient} />
						<PrivateRoute exact path="/searchClient" component={searchClient} />
						<PrivateRoute exact path="/searchClientResults" component={searchClientResults} /> */}

          {/* Referral management section */}
          <PrivateRoute
            exact
            path='/client-management'
            component={ReferralManagementPage}
          />
          <PrivateRoute
            exact
            path='/client-management/add-client'
            component={AddClientPage}
          />
          <PrivateRoute
            exact
            path='/client-management/list-client'
            component={ClientListPage}
          />
          <PrivateRoute
            exact
            path='/client-management/edit-client/:client_id'
            component={EditClientPage}
          />
          <PrivateRoute
            exact
            path='/client-management/client-profile'
            component={ClientProfilePage}
          />
          <PrivateRoute
            exact
            path='/client-management/products-add'
            component={InstrumentPage}
          />
          <PrivateRoute
            exact
            path='/client-management/products-list'
            component={ProductsListPage}
          />
          <PrivateRoute
            exact
            path='/client-management/products-summary'
            component={ProductsSummaryPage}
          />
          <PrivateRoute
            exact
            path='/client-management/checklist'
            component={ChecklistPage}
          />
          <PrivateRoute
            exact
            path='/client-management/draft-recommendation-report'
            component={DraftRecommendationReportPage}
          />
          <PrivateRoute
            exact
            path='/client-management/survey-form'
            component={SurveyFormPage}
          />
          <PrivateRoute
            exact
            path='/client-management/draft-recommendation-summary'
            component={DraftRecommendationSummaryPage}
          />
          <PrivateRoute
            exact
            path='/tasks/fund-status'
            component={DraftRecommendationSummaryTaskListPage}
          />
          <PrivateRoute
            exact
            path='/client-management/fee-reconciliation'
            component={FeeReconciliationPage}
          />
          <PrivateRoute
            exact
            path='/client-management/confirm-client'
            component={ConfirmClientPage}
          />
          <PrivateRoute
            exact
            path='/client-management/extracted-data'
            component={ExtractedDataPage}
          />

          {/* Task assignment section */}
          <PrivateRoute exact path='/tasks' component={TaskListPage} />
          <PrivateRoute exact path='/pendingApproval' component={ARLPage} />
          <PrivateRoute exact path='/arl' component={ARLMainPage} />
          <PrivateRoute
            exact
            path='/task-details/:task_id'
            component={TaskDetailsPage}
          />

          {/* Template Section */}
          <PrivateRoute exact path='/templates' component={Templates} />

          {/* Reminder page */}
          <PrivateRoute exact path='/reminders' component={RemindersPage} />

          {/* Dashbaord Settings  */}
          <PrivateRoute
            exact
            path='/profile-management'
            component={ProfileManagementDashboard}
          />
              <PrivateRoute
            exact
            path='/profile-management/settings'
            component={DashboardSettingsPage}
          />


          <Route path='*' component={PageNotFound} />
        </Switch>
      </Suspense>
      {/* </ErrorBoundary> */}
    </Router>
  )
}

export default App

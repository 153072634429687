import React, { useState, useEffect, useMemo } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '../Mui/Paper/Paper';
import Grid from '@material-ui/core/Grid';
import { saveAs } from 'file-saver';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import {  IconButton } from "@mui/material";
import Modal from '../Modal/Modal';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from "@material-ui/core/DialogActions";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import CallIcon from '@material-ui/icons/Call';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { makeStyles } from '@material-ui/core/styles';
import { getShortForm, downloadFileFromUrl,fetchGet,mapAdvisor } from '../../utils/utils';
import EmailIcon from '@material-ui/icons/Email';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DocumentList from '../DocumentList/DocumentList';
import TitleWithViewMore from '../TitleWithViewMore/TitleWithViewMore';
import { useHistory } from "react-router-dom";
import appConfig from '../../appConfig.json';
import NumberFormat from 'react-number-format';
import Modals from '../Modal/Modal';
import DialogContent from '@mui/material/DialogContent';
import { Autocomplete, TextField } from "@mui/material";
import SearchIcon from '@material-ui/icons/Search';
import { useSnackbar } from "notistack";
import _ from 'lodash';
import './ClientInfoBar.scss';
import Button from '../Button/Button';
import { Field, Form, Formik } from 'formik';
import SearchableSelectEmail from '../SearchableSelect/SearchableselectEmail';
import { clearDocConversionSurveyForm, clearPartnerSurveyData, clearSurveyData, getEmailSearchableList } from '../../redux/surveyForms/surveyForms.actions';
import { fetchPut} from '../../services/FeeMatchingService';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import ClientInfoBarMailView from '../ClientInfoBarMailView/ClientInfoBarMailView';
import { removeIndivigualFileItems } from '../../redux/ui/ui.actions';
import { axiosGet } from '../../utils/api';
import { clearAssetSectionFormStatus } from '../../redux/persistedReducer/persistedReducer.actions';


const useStyles = makeStyles(theme => {
    return {
        root: {
                    '& label.Mui-focused': {
                        color: theme.palette.secondary.main
                    },
                    '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                            borderColor: theme.palette.secondary.main
                        },
                    },
                    "& .MuiAutocomplete-popupIndicator": 
                    { 
                        color: theme.palette.secondary.main,
                        transform: "none" 
                
                    },
                    "& .MuiOutlinedInput-input":{
                        height:"0.9em !important"
                    }
                }
            ,
        
        detailsCircle: {
            background: theme.palette.secondary.main
        },
        vulnerableRestrictedCircle:{
            backgroundImage:"linear-gradient(to right, #e65050ff, #e6c250ff)"
        },
        vulnerableCircle:{
            backgroundColor:"#e65050ff"
        },
        restrictedCircle:{
            backgroundColor: "#e6c250ff"
        },
        button:{
            marginBottom:"30px"
        },
        buttonSubmit:{
          display:"flex !important",
          justifyContent:"flex-end !important",
        },
        clearIndicator: {
            padding: '4px',  // 4px padding for clear indicator
          },
          popupIndicator: {
            padding: '2px',  // 2px padding for popup indicator
          },
    }
});



const ClientInfoBar = ({
    src,
    email,
    name,
    phone,
    joinedOn,
    refferedBy,
    vulnerable,
    restricted,
    vulnerableStatusData,
    location,
    profession,
    elevation = 2,
    square = false,
    mb = 0,
    niNumber,
    expandable = true,
    netWorth
}) => {
    const surveyFormCategoryOrder=useMemo(()=>["Personal Information","Occupation Information","Income & Expenditure Summary","Net Worth Summary","Plans & ATR",
      "Recently Updated-Personal Information",
      "Recently Updated-Occupation Information",
      "Recently Updated-Income & Expenditure Summary",
      "Recently Updated-Net Worth Summary",
      "Recently Updated-Plans & ATR"
      ])
    const [isExpanded, setIsExpanded] = useState(false);
    const [confirmUpload,setConfirmUpload]=useState(false);
    const [openLoaUploadModal, setOpenLoaUploadModal] = useState(false);
    const clientMailvalue = {
      client_mail: ['shamil@techversantinfotech.com'],
      mail_cc: ['saamil@gmail.com', 'azasda@gmail.com'],
      mail_bcc: ['samil@gmail.com', 'afasfa@gmail.com'],
      subject: 'hey there new subject',
      content: 'dasdas asd asda '
  }
    const [isDocGenerationInProgress, setIsDocGenerationInProgress] = useState(false);
    const [matchMissingModal, setMatchMissingModal] = useState(false);
    const [selectedAdvisor, setSelectedAdvisor] = useState(null);
    const [suggestions, setSuggestions] = useState([]);
    const [searchName, setSearchName] = useState('');
    const [htmlText, setHtmlText] = useState('');
    const customStyles = useStyles();
    const clientDocs = useSelector(state => state.client?.clientDocs);
    const clientDetails = useSelector(state => state.client?.clientDetails);
    const history = useHistory();
    const locationHook = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const currentUserData = useSelector(state => state.auth.currentUserData);
    const dispatch  = useDispatch();
    const [options, setOptions] = useState([]);
    const intialSearchFieldValue={search_client:""}
    const [joinClientModal,setJoinClientModal]=useState(false)
    const isLoadingOptions  = useSelector(state => state.survey.isLoadingEmailList);
    const docConversionData  = useSelector(state => state.survey.docDataSurveyForm);
    const isDocConversionLoading  = useSelector(state => state.survey.isDocConversionLoading);
    const isLoadingPostSurveyForm  = useSelector(state => state.survey.isLoadingPostSurveyForm);
    const emailList       = useSelector(state => state.survey.emailList);
    const [ relativeId, setRelativeId ] = useState(null);
    const filterData = (data) => {
        const mainData = data.filter(item =>item.component_type!="SearchableButton"&&item.fieldName.slice(0, 7) !== 'partner');
        const partnerData = data.filter(item =>item.component_type!="SearchableButton"&&item.fieldName.slice(0, 7) === 'partner');
        return { mainData, partnerData };
      };

      const fetchProviderOptions = async()=>{
        await axiosGet('provider-email-list')
        .then(res => {
           const optionChangedForMultiSelect=res?.data?.data?.length>0?res.data.data.map((list) => {
            return ({...list,name:list["provider__name"]+"-"+list["email"]
            ,id:list["provider"]})
        }):[] 
          setOptions(optionChangedForMultiSelect)
        })
        .catch(err => console.log(err,'err'))
    }
    useEffect(()=>{
        fetchProviderOptions()
    },[])
      const categorizeDataByCategory = (docConversionData) => {
        const categorizedData = {};
        for (const topLevelCategory in docConversionData) {
          docConversionData[topLevelCategory].forEach(item => {
            if (!categorizedData[topLevelCategory]) {
              categorizedData[topLevelCategory] = {};
            }
            if (!categorizedData[topLevelCategory][item.category]) {
              categorizedData[topLevelCategory][item.category] = [];
            }
            categorizedData[topLevelCategory][item.category].push(item);
          });
        }
        return categorizedData;
      };
      const isJsonString = (str) => {
        try {
          let stringData
          if(str[0]=="["){
            stringData=str.replace(/'/g,'"')
          }

          if(_.isArray(str)){
            return str
          }
          
          

          const parsed = JSON.parse(stringData);
          return _.isArray(parsed) ? parsed : false;
        } catch (e) {
          return false;
        }
      };
      const isValidString = value => typeof value === 'string' && /^[a-zA-Z\s]+$/.test(value);
      function getSelectedAnswer(dataItem,dontCareOfAnswerParent=false) {
      
        if (dataItem?.answer&&(dataItem?.answer_parent?.length === 0 || dontCareOfAnswerParent)) {
          if (isJsonString(dataItem?.answer)) {
            return isJsonString(dataItem?.answer).map(item => item.option.toLowerCase());
          } else if (_.isArray(dataItem?.answer)) {
            return dataItem?.answer.map(item => item.option.toLowerCase());
          } else if (_.isObject(dataItem?.answer)) {
            return dataItem?.answer.option.toLowerCase();
          } else if (isValidString(dataItem?.answer)) {
            return dataItem?.answer.toLowerCase();
          } else {
            return dataItem?.answer;
          }
        }
      }
      const generateSublabelHTML = (dataItem) => {
        let selectedAnswer
            return dataItem?.sublabelData.map(subSubLabels => {
          if (subSubLabels && subSubLabels.sub_label_data && subSubLabels.sub_label_data.length > 0) {
            selectedAnswer=dataItem.component_type === "Date"?dataItem?.answer:getSelectedAnswer(dataItem,true)//dont care answer parent is true sometimes answer of sublabel has a sublabel(subSublabel) dont need to check answer parent 
           
            return subSubLabels.sub_label_data.map(subSubLabelData => {
              const answerParent=subSubLabelData?.answer_parent?.map((answerParent)=>isValidString(answerParent)?answerParent.toLowerCase():answerParent)
              const dataHideField =  (answerParent?.length === 0?true : (((answerParent?.includes(selectedAnswer)) || ((_.isArray(selectedAnswer) && selectedAnswer.some( item => answerParent?.includes(item)))))));
              return dataHideField?`<div style="font-family: 'Berthold Akzidenz Grotesk BE'; font-size: 12px;vertical-align: top; ${(subSubLabelData.answer!=""&&subSubLabelData.answer!=null)? "color:black" : "color:red"};">
             <h4>${subSubLabelData.label}:</h4>
             ${(subSubLabelData.answer !="" && subSubLabelData.answer != null) ? `<h4 style="font-weight: lighter;">${(subSubLabelData.answer!=0&&isJsonString(subSubLabelData.answer))
                ? isJsonString(subSubLabelData.answer).map(option => option.option).join(", ")
                : subSubLabelData.component_type === "Date" ? moment(subSubLabelData.answer).format("DD-MM-YYYY") : subSubLabelData.answer}</h4>` : ""}
            </div>`:""}).join('');
          }
          return '';
        }).join('');
      };
      const generateHTMLTable = (category, data) => {
        const { mainData, partnerData } = filterData(data);
        const maxLength = Math.max(mainData.length, partnerData.length);
        let  mainSelectedAnswer;
        let  partnerSelectedAnswer;
        return `
          <table style="width: 100%;">
            <thead>
              <tr>
                <td style="font-family: 'Berthold Akzidenz Grotesk BE'; font-size: 12px;font-style: italic;border: 2px solid black; "><h3><u>${mainData.length > 0 ? mainData[0].category : ''}</u></h3></td>
                ${partnerData.length > 0 ? `<td style="font-family: 'Berthold Akzidenz Grotesk BE'; font-size: 12px;font-style: italic;border: 2px solid black; "><h3><u>${partnerData[0].category}</u></h3></td>` : ''}
              </tr>
            </thead>
            <tbody>
              ${Array.from({ length: maxLength }, (item, index) =>{
               //showing only fields which response to parent's field ex:married status field changes to Single then there is no child fields
               if(mainData[index]?.answer_parent?.length==0){
                mainSelectedAnswer = mainData[index]?.component_type === "Date"?mainData[index]?.answer:getSelectedAnswer(mainData[index]);
               }
                if(partnerData[index]?.answer_parent?.length==0){
                  partnerSelectedAnswer = partnerData[index]?.component_type === "Date"?partnerData[index]?.answer:getSelectedAnswer(partnerData[index]);
                }

                let AnyFieldsFilledInPensionContributionOrAddAssetsChecker = {
                  mainPension: false,
                  partnerPension: false
                };
                
                function checkPensionContributions(data, checkerKey) {
                  if (data[index]?.label == "Pension contributions") {
                    if (data[index + 1]?.answer !="" && data[index + 2]?.answer != "") {
                      AnyFieldsFilledInPensionContributionOrAddAssetsChecker[checkerKey] = true;
                    } else {
                      AnyFieldsFilledInPensionContributionOrAddAssetsChecker[checkerKey] = false;
                    }
                  }
                }
                
                checkPensionContributions(mainData, "mainPension");
                checkPensionContributions(partnerData, "partnerPension");

                const componentType=["Date","Month/Year"]
                const removedFields=["Add assets"]
                const mainAnswerParent=mainData[index]?.answer_parent.map((answerParent)=>isValidString(answerParent)?answerParent.toLowerCase():answerParent)
                const partnerAnswerParent=partnerData[index]?.answer_parent.map((answerParent)=>isValidString(answerParent)?answerParent.toLowerCase():answerParent)
                const mainDataHideField =  (mainAnswerParent?.length === 0?true : (((mainAnswerParent?.includes(mainSelectedAnswer)) || ((_.isArray(mainSelectedAnswer) && mainSelectedAnswer.some( item => mainAnswerParent?.includes(item)))))));
                const partnerDataHideField =  (partnerAnswerParent?.length === 0?true : (((partnerAnswerParent?.includes(partnerSelectedAnswer)) || ((_.isArray(partnerSelectedAnswer) && partnerSelectedAnswer.some( item => partnerAnswerParent?.includes(item)))))));
                return `
               ${(mainDataHideField||partnerDataHideField)&&!removedFields.includes(mainData[index]?.label)? `<tr key=${index}>
                  ${(mainData[index]&&mainDataHideField&&!removedFields.includes(mainData[index]?.label)) ? `
                    <td style="font-family: 'Berthold Akzidenz Grotesk BE'; font-size: 12px;border-collapse: collapse;border: 2px solid black;padding: ${mainData[index].subLabel ? "25px" : "10px"}; vertical-align: top; ${((mainData[index].answer!=""&&mainData[index].answer!=null)||(AnyFieldsFilledInPensionContributionOrAddAssetsChecker?.mainPension))? "color:black" : "color:red"};">
                      <h4>${mainData[index].label}:</h4>
                      ${(mainData[index].answer!=""&&mainData[index].answer!=null)?`<h4 style="font-weight: lighter;">${isJsonString(mainData[index].answer)
                    ? isJsonString(mainData[index].answer).map(option => option.option).join(", ")
                    : componentType.includes(mainData[index].component_type)?moment(mainData[index].answer).format("DD-MM-YYYY"):mainData[index].answer}</h4>`:""}
                   ${mainData[index]?.sublabelData?.length > 0 ? generateSublabelHTML(mainData[index]) : ""}
                </td>
                    </td>
                  ` :""}
                  ${((!mainDataHideField || !partnerDataHideField)&&partnerData.length>0&&(clientDetails?.joint_client || clientDetails.parallelly_added))?`
                    <td style='border-collapse: collapse;border: 2px solid black;padding: 10px; vertical-align: top;'></td>
                  `:""}
                  ${(partnerData?.length>0&&partnerData[index]&&(partnerDataHideField)&&!removedFields.includes(partnerData[index]?.label)&&(clientDetails?.joint_client || clientDetails.parallelly_added)) ? `
                    <td style="font-family: 'Berthold Akzidenz Grotesk BE'; font-size: 12px;border-collapse: collapse;border: 2px solid black;padding: ${partnerData[index].subLabel ? "25px" : "10px"}; vertical-align: top; ${(partnerData[index].answer!=""&&partnerData[index].answer!=null ||AnyFieldsFilledInPensionContributionOrAddAssetsChecker?.partnerPension)? "color:black" : "color:red"};">
                      <h4>${partnerData[index].label}:</h4>
                      ${(partnerData[index].answer!=""&&partnerData[index].answer!=null)?`<h4 style="font-weight: lighter;">${isJsonString(partnerData[index].answer)
                    ? isJsonString(partnerData[index].answer).map(option => option.option).join(", ")
                    : (componentType.includes(partnerData[index].component_type))?moment(partnerData[index].answer).format("DD-MM-YYYY"):partnerData[index].answer}</h4>`:""}
                    ${partnerData[index]?.sublabelData?.length > 0 ? generateSublabelHTML(partnerData[index]) : ""}
                    </td>
                  ` : ""}
                </tr>`:""}
              `}).join('')}
            </tbody>
          </table>
        `;
      };
      const generateHTMLDocument = (docConversionData, surveyFormCategoryOrder, clientDetails) => {
        const categorizedData = categorizeDataByCategory(docConversionData);

  return surveyFormCategoryOrder.map(topLevelCategory => {
    if (categorizedData[topLevelCategory]) {
      return Object.keys(categorizedData[topLevelCategory]).map(subCategory => {
        const data = categorizedData[topLevelCategory][subCategory];
        return `
          <div style="font-family: 'Beyond Infinity';text-align:center;"><h1 style="font-size:35px">${topLevelCategory}</h1></div>
          ${generateHTMLTable(subCategory, data, clientDetails)}
        `;
      }).join('');
    }
    return '';
  }).join('');
      };
     const joinClientSubmit=async()=>{
        if(relativeId){
            const data={main_client:clientDetails.id,related_client:relativeId}
           const response= await fetchPut(userToken,"join_client",data)
           if (response.status) {
            setJoinClientModal(false)
         
            enqueueSnackbar(response.message, {
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              
            });
            window.location.reload();
          } else {
            
            setJoinClientModal(false)
            enqueueSnackbar(response.message, {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            });
          }
        }
        
     } 
     const handleDownload = async () => {
        const blob = new Blob([htmlText], { type: 'doc/plain;charset=utf-8' });
        saveAs(blob, 'surveyform.doc');
    };
    const clearLocalSurveyData = () => {
      localStorage.removeItem('assetsSection_'+clientDetails.id);
      localStorage.removeItem("surveyForm_"+clientDetails.id);
      dispatch(clearAssetSectionFormStatus())
  }
     useEffect(()=>{
        if(clientDetails?.relative_client){
            setRelativeId(clientDetails?.relative_client)
        }
        if(!locationHook.pathname.includes("/survey-form")){
          dispatch(clearDocConversionSurveyForm())
          dispatch(clearSurveyData());
          dispatch(clearPartnerSurveyData());
          clearLocalSurveyData()
        }
        dispatch(removeIndivigualFileItems([]))
     },[])
     useEffect(()=>{
        if(Object.keys(docConversionData).length>0 && locationHook.pathname.includes("/survey-form")){
            const htmlContent = generateHTMLDocument(docConversionData, surveyFormCategoryOrder, clientDetails);     
               setHtmlText(htmlContent)
               setIsDocGenerationInProgress(true)
        }
        
        
     },[docConversionData])
      const renderStatusDescription = (vulnerable, restricted, vulnerableStatusData) => {
        const renderDescription = (title, description) => (
            <div style={{paddingLeft:"8px"}}>
              <span style={{color:(title=="Vulnerable")?"#e65050ff":"#e6c250ff",fontWeight:"500",fontSize:"14px"}}>{title}</span><span style={{fontSize:"14px"}}> - {description || "No Description Found"}</span>
            </div>
          );
        if (!vulnerableStatusData) {
            return <div>No Description Found</div>;
        }
        else{
             if (vulnerable && restricted) {
          return (
            <>
              {renderDescription("Vulnerable", vulnerableStatusData.vulnerable_des)}
              {renderDescription("Restricted", vulnerableStatusData.restricted_des)}
            </>
          );
        } else if (vulnerable) {
          return renderDescription("Vulnerable", vulnerableStatusData.vulnerable_des);
        } else if (restricted) {
          return renderDescription("Restricted", vulnerableStatusData.restricted_des);
        } else {
          return <div>No Description Found</div>;
        }
      }
        }
        const getClassNames = (vulnerableRestrictedClassname,vulnerableClassname, restrictedClassname, defaultClassname) => {
            const className = (vulnerable && restricted)
              ?vulnerableRestrictedClassname
              : (vulnerable ? vulnerableClassname : (restricted ? restrictedClassname : defaultClassname));
          ;
          
            return className
          };
       
          const userToken = useSelector((state) => state.auth.currentUserData.token);
          useEffect(() => {
          
            const fetchSuggestions = async () => {
              try {
                const geturlPrv = `advisor-search-fee-split/?name=${searchName}`;
                const getdataPrvDrop = await fetchGet(userToken, geturlPrv);
        
                if (getdataPrvDrop && getdataPrvDrop.status_code === '200' && getdataPrvDrop.data.length) {
                  setSuggestions(getdataPrvDrop.data);
                } else {
                  setSuggestions([]);
                }
              } catch (error) {
                console.error('Error fetching advisor data:', error);
              }
            };
        
            
            const delay = 300;
            const timer = setTimeout(() => {
              fetchSuggestions();
            }, delay);
        
            return () => clearTimeout(timer); 
          }, [searchName, userToken]);

          const handleMatch = async () => {
            if (selectedAdvisor && selectedAdvisor.id) {
              const clientId =  (clientDetails.id) 
              const advisorName=`${selectedAdvisor.user.first_name} ${selectedAdvisor.user.last_name}`
              const formData = new FormData();
              formData.append('advisor', selectedAdvisor.id); 
        
            
            mapAdvisor(userToken, 're-map-advisor', clientId, formData)
            .then((res) => {
                if (res.status) {
                  setMatchMissingModal(false)
               
                  enqueueSnackbar(res.message, {
                    variant: 'success',
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                    
                  });
                  history.push('/client-management/list-client', { page_name: 'Client List', addedByMe: true, enable_cold_calling: false, currentUserData: currentUserData })  
                } else {
                  enqueueSnackbar(res.message, {
                    variant: 'error',
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                  });
                }
              })
              .catch((error) => {
                enqueueSnackbar('error to re-map the advisor', {
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                  },
                });
              });
          };
            }
            const currentLoging  = useSelector((state) => {
              const firstName = state.auth.currentUserData.first_name;
              const lastName = state.auth.currentUserData.last_name;
              return `${firstName} ${lastName}`;
            });
      
            let relativeClientName = clientDetails?.relative_client_name;  
            const filteredSuggestions = suggestions.filter(
              (advisor) => `${advisor.user.first_name} ${advisor.user.last_name}` !== currentLoging
            );   
          
    return (
        <Accordion 
            className="accordionDiv"
            onChange={(event, expanded)=>{
                if(expanded)
                {
                    setIsExpanded(true)
                }
                else
                {
                    setIsExpanded(false)
                }
            }}
        >
            <Grid container>
                <Grid item xs={expandable ? 11 : 12}>
                    {/* Client info bar starts  */}
                    <Box mb={mb} className="ClientInfoBar">
                        <Paper elevation={elevation} className="ClientInfoBarPaper" square={square}>
                            <Box p={0}>
                                <Grid item container alignItems="center" justify="center" >
                                    <Grid item xs sm={12} lg={3}>
                                        <div className="detailsWrapper detailsWrapperUser">
                                            <div className="detailsLeft" onClick={() => history.push('/client-management/client-profile', {clientId:clientDetails?.id})}>
                                                <span className={getClassNames("vulnerable-restricted","vulnerable","restricted","detailsCircleName")}>
                                                    {(name != "" && name.includes('undefined') !== true) && getShortForm(name)}
                                                </span>
                                                
                                            </div>
                                            <div className="detailsRight">
                                                {(name != "" && name.includes('undefined') !== true) && <div>
                                                <Typography className={`detailsName`} onClick={() => history.push('/client-management/client-profile', {clientId:clientDetails?.id})}>{name}{" "}{(clientDetails?.joint_client || clientDetails?.parallelly_added) && relativeClientName 
        ? `& ${relativeClientName}`: ""}{(vulnerable && restricted) ?
                                                    <span > <span style={{color:"#e65050ff",fontSize:"14px"}}><span style={{background:"linear-gradient(to right, #e65050ff, #e6c250ff)",WebkitBackgroundClip:"text",WebkitTextFillColor:"transparent",width:"100px"}}>{" - "}</span>Vulnerable & </span><span style={{color:"#e6c250ff",fontSize:"14px"}}>Restricted</span></span> : (vulnerable ?<span style={{color:"#e65050ff",fontSize:"14px"}}>{" - "}Vulnerable</span> : (restricted ? <span style={{color:"#e6c250ff",fontSize:"14px"}}>{" - "}Restricted</span> : ""))}</Typography>
                                                    <Typography className="detailsProfession">{profession}</Typography>
                                                    {niNumber && <Typography className="detailsLocation">NI No: {niNumber}</Typography>}
                                                </div>}
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg>
                                        <div className="detailsWrapper">
                                            <div className="detailsLeft">
                                                <div className={getClassNames(`detailsCircle ${customStyles.vulnerableRestrictedCircle}`,`detailsCircle ${customStyles.vulnerableCircle}`,`detailsCircle ${customStyles.restrictedCircle}`,`detailsCircle ${customStyles.detailsCircle}`)}>
                                                    <CallIcon />
                                                </div>
                                            </div>
                                            <div className="detailsRight detailsRegular">
                                                <div>
                                                    <Typography className="detailsHead" variant="caption">Phone</Typography>
                                                    <Typography className="detailsData" variant="caption">{phone}</Typography>
                                                </div>
                                            </div>
                                        </div>

                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={2}>
                                        <div className="detailsWrapper">
                                            <div className="detailsLeft">
                                                <div className={getClassNames(`detailsCircle ${customStyles.vulnerableRestrictedCircle}`,`detailsCircle ${customStyles.vulnerableCircle}`,`detailsCircle ${customStyles.restrictedCircle}`,`detailsCircle ${customStyles.detailsCircle}`)}>
                                                    <EmailIcon />
                                                </div>
                                            </div>
                                            <div className="detailsRight detailsRegular">
                                                <div className="detailsRightWrapper">
                                                    <Typography className="detailsHead" variant="caption">Email</Typography>
                                                    <Typography className="detailsData emailDetails" variant="caption">{email}</Typography>
                                                </div>
                                            </div>
                                        </div>

                                    </Grid>
                                    <Grid item xs={12} sm={6} lg>
                                        <div className="detailsWrapper">
                                            <div className="detailsLeft">
                                                <div className={getClassNames(`detailsCircle ${customStyles.vulnerableRestrictedCircle}`,`detailsCircle ${customStyles.vulnerableCircle}`,`detailsCircle ${customStyles.restrictedCircle}`,`detailsCircle ${customStyles.detailsCircle}`)}>
                                                    <CalendarTodayIcon />
                                                </div>
                                            </div>
                                            <div className="detailsRight detailsRegular">
                                                <div>
                                                    <Typography className="detailsHead" variant="caption">Joined on</Typography>
                                                    <Typography className="detailsData" variant="caption">{joinedOn}</Typography>
                                                </div>
                                            </div>
                                        </div>

                                    </Grid>
                                    <Grid item xs={12} sm={6} lg>
                                        <div className="detailsWrapper">
                                            <div className="detailsLeft">
                                                <div className={getClassNames(`detailsCircle ${customStyles.vulnerableRestrictedCircle}`,`detailsCircle ${customStyles.vulnerableCircle}`,`detailsCircle ${customStyles.restrictedCircle}`,`detailsCircle ${customStyles.detailsCircle}`)}>
                                                    <AccountBalanceIcon />
                                                </div>
                                            </div>
                                            <div className="detailsRight detailsRegular">
                                                <div>
                                                    <Typography className="detailsHead" variant="caption">Net Worth</Typography>
                                                    <Typography className="detailsData" variant="caption">
                                                        {(netWorth && netWorth > 0) ? <NumberFormat value={netWorth} displayType={'text'} thousandSeparator={true} prefix={appConfig.currency.currency_symbol} /> : 'NA'}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>

                                    </Grid><Grid item xs={12} sm={6} lg={1}>
                                    {(isDocGenerationInProgress && !isDocConversionLoading &&!isLoadingPostSurveyForm) ? (
    <div style={{ display: 'flex', justifyContent: 'flex-end',alignItems:"center",cursor:"pointer" }} onClick={handleDownload}>
      <IconButton  style={{ color: "#32c2d9" }}>
        <DownloadForOfflineIcon fontSize='large'/>
      </IconButton>
      <h6 style={{marginBottom:"0.1rem",fontSize:"14.2px"}}>Download as doc</h6>
    </div>
  ) : (
    (isDocConversionLoading || isLoadingPostSurveyForm) && <CircularProgress style={{ color: "#32c2d9" }} size="1.5rem" />
  )}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Box>
                    {/* Client info bar ends  */}
                </Grid>
                {
                    expandable
                    &&
                    <Grid item xs={1} >
                        <div style={{display:"flex",height:"100%"}} className="profileExpand">
                            <AccordionSummary
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className="profileExpandIconContainer"
                            >
                                {isExpanded ? (
                                    <RemoveCircleOutlineIcon className="addCircle cursor-pointer" />
                                ) : (
                                        <AddCircleOutlineIcon className="addCircle cursor-pointer" />
                                    )}
                            </AccordionSummary>
                        </div>
                    </Grid>
                }

            </Grid>
            <AccordionDetails className="expandContainer">
                <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6}>
                   </Grid>
                <Grid item xs={6} sm={6} md={6} style={{display:"flex",gap:"30px"}} >
                   <button className="button-download" onClick={()=>downloadFileFromUrl(`${process.env.REACT_APP_BASE_URL}loa-document-download/${clientDetails?.id}`,"loa-document-download",true,userToken)}><FileDownloadOutlinedIcon/>LOA Download</button>
                   <button className="button-download" onClick={()=>setOpenLoaUploadModal(true)}><FileUploadOutlinedIcon/>LOA Upload</button>
                   </Grid>
                    {/* Left side block */}
                    <Grid item xs={12} sm={12} md={6}>
                        <div className="titleText">
                            <TitleWithViewMore title={'ABOUT'} />
                        </div>
                        <Typography >
                            {clientDetails.about ? clientDetails.about : "Data will be available soon!"}
                        </Typography>
                        <div className="referredByBlock">
                        <div className="referredTitle">
                                <div style={{fontWeight:500}}>Description:</div>
                            </div>
                            <Typography>
                                 {renderStatusDescription(vulnerable, restricted, vulnerableStatusData)}
                            </Typography></div>
                        <div className="referredByBlock" sm={12}>
                            <span className="referredTitle">
                                <TitleWithViewMore title={'Referred by'} />
                            </span>
                            { (clientDetails) &&
                            <Grid item container >
                                <Grid item xs={12} sm={6}>
                                    <div className="reffereddetailsWrapper reffereddetailsWrapperUser">
                                        {clientDetails.referred_user_first_name ? (
                                            <div className="reffereddetailsCircle refferediconColor referredNameText">
                                                {getShortForm(clientDetails.referred_user_first_name + " " + clientDetails.referred_user_last_name)}
                                            </div>
                                        ) : (
                                            <div className="reffereddetailsCircle refferediconColor referredNameText">
                                                {getShortForm("New")}
                                            </div>
                                        )}
                                        
                                        {clientDetails.referred_user_first_name ? (
                                            <div className="reffereddetailsRight">
                                                <Typography className="reffereddetailsHead ">
                                                    {clientDetails.referred_user_first_name + " " + clientDetails.referred_user_last_name}
                                                </Typography>
                                                <Typography className="reffereddetailsData" >{clientDetails?.referred_user_email || ''}</Typography>
                                            </div>
                                        ):(
                                            <div className="reffereddetailsRight nameOnly">
                                                <Typography className="reffereddetailsHead">New</Typography>
                                            </div>
                                        )}
                                    </div>
                                </Grid>
                                {clientDetails?.referred_user_phone_number &&
                                    <Grid item xs={12} sm={6}>
                                        <div className="reffereddetailsWrapper">
                                            <div className="reffereddetailsLeft">
                                                <div className="reffereddetailsCircle refferediconColor">
                                                    <CallIcon />
                                                </div>
                                            </div>
                                            <div className="reffereddetailsRight">
                                                <div>
                                                    <Typography className="reffereddetailsData" >Phone</Typography>
                                                    <Typography className="reffereddetailsHead" >{clientDetails.referred_user_phone_number}</Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                }
                            </Grid>
                            }

                        </div>

                        <div className="referredByBlock" sm={12}>
    <span className="referredTitle">
        <TitleWithViewMore title={'Created by'} />
    </span>
    <div className='advioserName'>
        { (clientDetails && clientDetails?.advisor) &&
            <Grid container alignItems='center'>
                <Grid item xs={12} sm={6}>
                    <div className="reffereddetailsWrapper reffereddetailsWrapperUser">
                    <div className="reffereddetailsCircle refferediconColor referredNameText">
                            {getShortForm(clientDetails?.old_advisor!=null?clientDetails.old_advisor:clientDetails?.advisor)}
                        </div>
                        <div className="reffereddetailsRight nameOnly">
                            <Typography className="reffereddetailsHead">
                                {clientDetails?.old_advisor!=null?clientDetails.old_advisor:clientDetails?.advisor}
                            </Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>
        }
    </div>
    <div className="referredByBlock" sm={12}>
    <span className="referredTitle">
        <TitleWithViewMore title={'Assign to'} />
    </span>
    <div className='advioserName'>
        { (clientDetails && (clientDetails?.advisor || clientDetails?.assigned_advisor)) &&
            <Grid container alignItems='center'>
                <Grid item xs={12} sm={4}>
                    <div className="reffereddetailsWrapper reffereddetailsWrapperUser">
                        <div className="reffereddetailsCircle refferediconColor referredNameText">
                            {getShortForm(clientDetails?.assigned_advisor!=null?clientDetails.assigned_advisor:clientDetails?.advisor)}
                        </div>
                        <div className="reffereddetailsRight nameOnly">
                            <Typography className="reffereddetailsHead">
                                {clientDetails?.assigned_advisor!=null?clientDetails.assigned_advisor:clientDetails?.advisor}
                            </Typography>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={4} className="buttonContainerss">
                    <Button
                        onClick={() => setMatchMissingModal(true)}
                        bgColor ='secondary'
                        width="125px"
                    >
                        Map Advisor
                    </Button>
                </Grid>
                <Grid item xs={12} sm={4} className="buttonContainerss">
                <Button
                        onClick={() => {
                            if(clientDetails && clientDetails?.
                                joint_client){
                                    joinClientSubmit()
                                }
                                else{
                                    setJoinClientModal(true)
                                }
                            
                        }
                        }
                        bgColor ='secondary'
                        width="125px"
                        disabled={clientDetails?.parallelly_added === true}
                    >
                    {(clientDetails && clientDetails?.
                            joint_client)?"Unlink":"Join Client"}
                    </Button>
                </Grid>
            </Grid>
        }
    </div>
    </div>
    <Modals
        open={joinClientModal}
        handleClose={() =>setJoinClientModal(false)}
        showDefaultCloseIcon
        size="xs"
        className=""
      >
        <DialogContent style={{paddingBottom:"50px"}}>
          <h2 className="snoozeHead customHead">Relative Account</h2>
          <div className="snoozeSubHeadWrapper">
            <h2 className="snoozeSubHead discardMessage customDescription">{" "}</h2>
            <hr />
            <div className="snoozeChangeSelectWrapper">
            <Formik
        initialValues={intialSearchFieldValue}
        onSubmit={()=>{
            joinClientSubmit()
        }}
        
        >
             {() => {
        return <Form>
            <Grid container spacing={2}>
  <Grid item xs={12}> 
    <Grid container spacing={2} alignItems="center">
 
      <Grid item xs={8}>
        
           
         <Field
         component={SearchableSelectEmail}
         name="search_client"
         label="Relative Account"
         jointAccount={true}
         required
         className="eventModalItem"
         options={emailList.filter(option=>{
            if(clientDetails?.id!=option?.id && !option?.parallel&&!option?.joint_client){
                return option
            } 
         })}
         getRelativeId={(e)=>{setRelativeId(e)}}
         onInputChange={(e, value, reason) => {
             dispatch(getEmailSearchableList(e.target.value));
         }}
         loading={isLoadingOptions}
         optionLabel="name"
         creatable={false}
         clearOnBlur
         convertToCase="upperCase"
         noOptionsText="Type here to search"
         customHelperText="Note:only clients without join and parallel account can be selected"
                />
             
       
      </Grid>
      <Grid item xs={4}>
      <Button
          bgColor="secondary"
          type="submit"
          className={customStyles.button}
        >
          Join
        </Button>
      </Grid>
    </Grid>
  </Grid>
</Grid> </Form>  }}</Formik>
            </div>
          </div>
        </DialogContent>
      </Modals>
      <Modal
            open={openLoaUploadModal}
            handleClose={()=>{setOpenLoaUploadModal(false);dispatch(removeIndivigualFileItems([]))}}
            size='md'
            showDefaultCloseIcon
        >
           <ClientInfoBarMailView providerOptions={options} afterSuccessfullySent={() => {
                        setOpenLoaUploadModal(false)
                        dispatch(removeIndivigualFileItems([]))
                    }} client_details={clientDetails&&clientDetails} close={() => {
                      setOpenLoaUploadModal(false)
                      dispatch(removeIndivigualFileItems([]))
                    }} className="mailModal" clientMailvalue={clientMailvalue} />
               
        </Modal>
      {/* <Modal
            open={confirmUpload}
            handleClose={()=>setConfirmUpload(false)}
            size='xs'
            showDefaultCloseIcon
        >
           
           <DialogTitle>Upload LOA</DialogTitle>
           <DialogContent >
            <h5>Are you sure you want to send the email to these providers</h5>
           <DialogActions className="modalFooter">
           <Button onClick={() => {
                                                        setConfirmUpload(false)
                                                        setOpenLoaUploadModal(true);
                                                    }}
                                                    color="primary" customStyle={{ background: 'white', marginRight: '10px' }} width='150px'
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        setConfirmUpload(false)
                                                    }}
                                                    color="primary" autoFocus bgColor="secondary" width='150px' customStyle={{ marginRight: '20px' }}
                                                >
                                                    Send Email
                                                </Button>
                                               
                                            </DialogActions>
            </DialogContent>
            
              
        </Modal> */}
    <Modals
        open={matchMissingModal}
        handleClose={() => setMatchMissingModal(false)}
        showDefaultCloseIcon
        size="xs"
        className=""
      >
        <DialogContent>
          <h2 className="snoozeHead customHead">Match Advisor</h2>
          <div className="snoozeSubHeadWrapper">
            <h2 className="snoozeSubHead discardMessage customDescription">{" "}</h2>
            <hr />
            <div className="snoozeChangeSelectWrapper">
            <Grid container spacing={2}>
  <Grid item xs={12}>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={8}>
        <Autocomplete
          id="advisorName"
          name="advisorName"
          className={customStyles.root}
          options={filteredSuggestions}
          getOptionLabel={(option) => `${option.user.first_name} ${option.user.last_name}`}
          popupIcon={<SearchIcon />}
          classes={{
            clearIndicator: customStyles.clearIndicator, // Apply custom clear indicator styles
            popupIndicator: customStyles.popupIndicator, // Apply custom popup indicator styles
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Enter Advisor Name"
              variant="outlined"
              size="small"
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
          openOnFocus
          onChange={(event, newValue) => {
            if(newValue){
              setSelectedAdvisor(newValue)
            }
            }}
          onInputChange={(e) =>setSearchName(e.target.value || "")}
        />
      </Grid>
      <Grid item xs={4}>
        <Button
          bgColor="secondary"
          onClick={handleMatch}
        >
          Match
        </Button>
      </Grid>
    </Grid>
  </Grid>
</Grid>
            </div>
          </div>
        </DialogContent>
      </Modals>

                        </div>
                    </Grid>
                    {/* Left side block end*/}


                    {/* Right side block */}
                    <Grid item xs={12} sm={12} md={6} className="customScrollbar">
                    <div className="documentWrapper">
                        <DocumentList
                            documents={clientDocs}
                            showType={false}
                            showActions={true}
                            showDownloadIcon={true}
                            onDownloadClick={(url, filename)=> {
                                downloadFileFromUrl(url, filename)
                            }}
                            firstCharsLength={8}
                            lastCharsLength={8}
                        />
                    </div>
                    </Grid>
                    {/* Right side block end */}
                </Grid>
            </AccordionDetails>
        </Accordion>


    )


}

export default ClientInfoBar;

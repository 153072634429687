import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getError, stringCaseSwitcher } from '../../utils/utils';
import InputAdornment from '@material-ui/core/InputAdornment';
import striptags from 'striptags';
import { TextField } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

// import {getDataUsingPostcode} from "../../services/draftService";
// import {  useSelector } from 'react-redux';
const useStyles = makeStyles(theme => {
    return {
        root: {
            '& label.Mui-focused': {
                color: theme.palette.secondary.main
            },
            // outlined text field border color changing
            '& .MuiOutlinedInput-root': {
                '&.Mui-disabled': {
                    '& fieldset': {
                        borderColor: theme.palette.common.themeGrayLight
                    }
                },
                '&.Mui-focused fieldset': {
                    borderColor: theme.palette.secondary.main
                },
            },
            // in case of standard text field
            '& .MuiInput-underline': {
                '&::before': {
                    borderBottom: `1px solid #eeeeee`
                },
                '&::after': {
                    borderBottom: `2px solid ${theme.palette.secondary.main}`
                },
                '&.Mui-error': {
                    '&::after': {
                        borderBottom: `2px solid ${theme.palette.error.main}`
                    }
                },
                '&:hover:not(.Mui-disabled):before': {
                    borderBottom: `2px solid ${theme.palette.secondary.main}`
                },
            },
        },
        labelCenter:{
            transform:'translate(14px, 10px) scale(1)'
        }
    }
});






const Input = ({
    field: { name, value, ...otherFieldProps },
    form: { touched, errors, values, setFieldValue, status, handleChange, setFieldTouched },
    onChange,
    type,
    label,
   
    variant = "outlined",
    children,
    size = "small",
    required,
    multiline,
    className,
    rows = 4,
    height,
    readOnly = false,
    disabled = false,
    inputLabelStyle=true,
    convertToCase = '',
    endAdornment=null,
    stripTags=false,
    allowedTags=[], // usage => striptags(html, allowed_tags, tag_replacement);
    onBlur,
    onKeyDown,
    onKeyUp,
    label1,
    
    ...otherProps
}) => 
{
   

    // const authToken = useSelector((state) => state.auth.currentUserData.token);
    const customStyles = useStyles();
    const errorText = getError(name, { touched, status, errors });
    const isError = (errorText) ? true : false;
    let cleanValue = value;
    if(stripTags && isNaN(value)){
        cleanValue = striptags(value, allowedTags);
    }
    // const getAllData = async(value) =>{
    //     let data = await getDataUsingPostcode(authToken, value);
    //     if(data && data.data){
    //     setFieldValue('contact_details_town_171', data.data.result.parish);
    //     setFieldValue('contact_details_region_172', data.data.result.admin_county);
    //     }
    // }
    return (
        <Tooltip title={label} placement="top">
        <TextField
            fullWidth
            label={label}
            type={type}
            name={name}
            value={cleanValue}
            helperText={errorText}
            FormHelperTextProps={{ style: {marginTop:"2px"}}}
            error={isError}
            variant={variant}
            inputProps={{style:{"height":height? height:"23px"}}}
            InputLabelProps={inputLabelStyle?{className:customStyles.labelCenter}:{}}
            {...otherFieldProps}
            size={size}
            className={`
            ${className}
            ${customStyles.root}
        `}
            required={required}
            multiline={multiline}
            onChange={(e) => {
                if(convertToCase==="titleCase"){
                const inputElement = e.target;
    const cursorPosition = inputElement.selectionStart; // Capture the current cursor position
    const rawValue = e.target.value;
    const value = convertToCase ? stringCaseSwitcher(convertToCase, rawValue) : rawValue;

    setFieldValue(name, value, false); // Update the field value without triggering an immediate re-render

    // Use a timeout to ensure the cursor position is restored after state update
    setTimeout(() => {
        inputElement.setSelectionRange(cursorPosition, cursorPosition); // Restore cursor position
    }, 0);
}
else{
    const value = convertToCase ? stringCaseSwitcher(convertToCase, e.target.value) : e.target.value;

setFieldValue(name, value);
}
                // if(name=='contact_details_postcode_173'&&value.length>4) {
                
                //         getAllData(value)
                   
                // }
               
                if (onChange)
                {
                    // Running the custom on change function if passed
                    if (onChange)
                    {
                        onChange(e);
                    }
                }
            }}
            rows={rows}
            InputProps={{
                endAdornment: endAdornment ? <InputAdornment position="end">{endAdornment}</InputAdornment> : null,
                autoComplete: 'off', // disable autocomplete and autofill
                readOnly: readOnly,
            }}
            disabled={disabled}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            onKeyUp={(e)=>{setFieldTouched(name, true); if(onKeyUp){onKeyUp(e)}}}
        />
        </Tooltip>

    )
}


export default Input;
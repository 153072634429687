import axios from 'axios';
import { store } from '../../src/redux/store';
// import { API_HOSTNAME } from './constants';
import { logOutUserWithoutTokenNonAsync } from '../redux/auth/auth.action';
import { showGlobalSnackBar } from '../redux/ui/ui.actions';
import appConfig from '../appConfig.json';

export const BASE_URL = process.env.REACT_APP_BASE_URL
export const getPath = url => `${BASE_URL}${url}`;

export const axiosSetup = () => {
    const { dispatch } = store;
    const UNAUTHORIZED = 401;
    axios.interceptors.response.use(
        response => response,
        error => {
            if(error.response)
            {
                const responseData = error.response;
                const status = responseData?.status;
                const isAuthorizationError = responseData.data?.data?.isAuthorizationError ? true : false;
                if (status === UNAUTHORIZED) // if invalid token logout user immediately
                {
                    dispatch(logOutUserWithoutTokenNonAsync());
                }
                // else if(status !== 400)  // for all non 400 cases show toast
                // {
                //     dispatch(showGlobalSnackBar({
                //         message : appConfig.messages.general_error_message,
                //         globalSnackBarType : 'error'
                //     }))
                // }
                else if(status === 400 && isAuthorizationError)  // handling authorization error globally
                {
                    const authorizationErrorMsg = responseData.data.message;
                    dispatch(showGlobalSnackBar({
                        message : authorizationErrorMsg,
                        globalSnackBarType : 'error'
                    }))
                }
            }
            if (error?.code === 'ECONNABORTED') {
                dispatch(showGlobalSnackBar({
                    message : appConfig.messages.api_time_out_error_message,
                    globalSnackBarType : 'error'
                }))
            }
            return Promise.reject(error);
        }
    );
};


export const axiosGet = (url, extraConfigs = {},withToken = true,month,year,x) => {
    const userData = store.getState().auth.currentUserData;
    const accessToken = userData ? userData.token : null;
    let headers;
    if (withToken)
    {
        headers = {
            Authtoken: 'Token ' + accessToken,
            type: 'application/json'
        }
    }
    else
    {
        headers = {
            type: 'application/json'
        }
    }
    const config = {
        headers: {
            ...headers
        },
        timeout: 300000, // 5 min timeout
        ...extraConfigs
    };
    return axios.get(getPath(x?`${url}?month=${month}&year=${year}`:url), config);
};

export const axiosGett = (url, extraConfigs = {}, withToken = true) => {
    const userData = store.getState().auth.currentUserData;
    const accessToken = userData ? userData.token : null;
    let headers;
    if (withToken)
    {
        headers = {
            Authtoken: 'Token ' + accessToken,
            type: 'application/json'
        }
    }
    else
    {
        headers = {
            type: 'application/json'
        }
    }
    const config = {
        headers: {
            ...headers
        },
        timeout: 300000, // 5 min timeout
        ...extraConfigs
    };
    return axios.get(getPath(url), config);
};
export const axiosPost = (url, data, extraConfigs = {}, withToken = true, extraHeaders = null) => {
    const userData = store.getState().auth.currentUserData;
    const accessToken = userData ? userData.token : null;
    let headers;
    if (withToken)
    {
        headers = {
            Authtoken: 'Token ' + accessToken,
            type: 'application/json'
        }
    }
    else
    {
        headers = {
            type: 'application/json'
        }
    }
    const config = {
        headers: {
            ...headers,
            ...extraHeaders
        },
        timeout: 300000, // 5 min timeout,
        ...extraConfigs
    };
    return axios.post(getPath(url), data, config);
};


export const axiosPatch = (url, data, extraConfigs = {}, withToken = true) => {
    const userData = store.getState().auth.currentUserData;
    const accessToken = userData ? userData.token : null;
    let headers;
    if (withToken)
    {
        headers = {
            Authtoken: 'Token ' + accessToken,
            type: 'application/json'
        }
    }
    else
    {
        headers = {
            type: 'application/json'
        }
    }
    const config = {
        headers: {
            ...headers
        },
        timeout: 300000, // 5 min timeout
        ...extraConfigs
    };
    return axios.patch(getPath(url), data, config);
};


export const axiosPut = (url, data, extraConfigs = {}, withToken = true) => {
    const userData = store.getState().auth.currentUserData;
    const accessToken = userData ? userData.token : null;

    let headers;
    if (withToken)
    {
        headers = {
            Authtoken: 'Token ' + accessToken,
            type: 'application/json'
        }
    }
    else
    {
        headers = {
            type: 'application/json'
        }
    }

    const config = {
        headers: {
            ...headers
        },
        timeout: 300000, // 5 min timeout
        ...extraConfigs
    };
    return axios.put(getPath(url), data, config);
};


export const axiosDelete = (url, data, extraConfigs = {}, withToken = true) => {
    const userData = store.getState().auth.currentUserData;
    const accessToken = userData ? userData.token : null;

    let headers;
    if (withToken)
    {
        headers = {
            Authtoken: 'Token ' + accessToken,
            type: 'application/json'
        }
    }
    else
    {
        headers = {
            type: 'application/json'
        }
    }
    const config = {
        headers: {
            ...headers
        },
        timeout: 300000, // 5 min timeout
        ...extraConfigs,
        data
    };
    return axios.delete(getPath(url), config);
};



export const handleOnSuccess = (response, successType) => {
    const { status, data } = response;
    switch (status)
    {
        case 200:
        case 201:
        case 206:
            if (data.status)
            {
                return {
                    type: successType,
                    payload: data,
                };
            }
            break;
        default:
            if (data.status) //return the same structure even if its not in 20x status codes
            {
                return {
                    type: successType,
                    payload: data,
                };
            }
    }
}

export const handleOnSuccessParallel = (response, successType) => {
    const { status, data } = response;
    switch (status)
    {
        case 200:
        case 201:
        case 206:
            if (data[0].status)
            {
                return {
                    type: successType,
                    payload: data,
                };
            }
            break;
        default:
            if (data[0].status) //return the same structure even if its not in 20x status codes
            {
                return {
                    type: successType,
                    payload: data,
                };
            }
    }
}
export const handleOnError = (response, errorType, errorWithData = false) => {
    const status = (response && response.status) || 599;

    // if there is non_field_errors inside data of response show that, if not show message that comes in response if thats not available show axios message if thats not available show custom front end defined message
    const customErrorMessageInside = (response && response.data && response.data.data && response.data.data.non_field_errors && response.data.data.non_field_errors[0]);
    const responseMessage = (response && response.data && response.data.message);
    const message =  customErrorMessageInside || responseMessage ;
    if (status >= 500 && status <= 600) // handling internal server errors, there will be no response.data
    {
        return {
            type: errorType,
            payload: {
                message: message
            }
        }
    }
    else if (status >= 400 && status < 500) // handling 404 and 400 related errors
    {
        return {
            type: errorType,
            payload: {
                ...response.data,
                message: message
            }
        }
    }
    else
    {
        return {
            type: errorType,
            payload: {
                ...response.data,
                message: message,
            },
        }
    }
}




export const handleActionStart = (actionType, payload = {}) => {
    if (payload)
    {
        return {
            type: actionType,
            payload: payload
        }
    }
    else
    {
        return {
            type: actionType,
        }
    }
}
import React, { useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';
import { CircularProgress, FormControl, TextField } from "@mui/material";
import { Formik, Form, Field } from 'formik';
import { EditorState,SelectionState, convertToRaw, convertFromHTML, ContentState ,Modifier} from "draft-js"
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../../Style/ModalMatch.css"
import '../../../assets/scss/customstyle.scss';
import draftToHtml from 'draftjs-to-html';
import { fetchGetEmail } from "../../../services/FeeMatchingService"
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import htmlToDraft from 'html-to-draftjs';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import './Dashboardmail.scss'
import { axiosGet, axiosPost } from "../../../utils/api";
import Button from "../../Button/Button";


function DashbaordMailView(props) {
  const currentUserEmail = useSelector(state => state.auth.currentUserData.email);
  const [zoomMeetingLink,setZoomMeetingLink]=useState("")
  // Default content
  const defaultContent = `<p>Hi ${props?.client_details?.first_name}</p>\n </br> <p>I hope you're well.</p> \n </br> <p>It'd be good to arrange a catch up to review the financial plan and let you know how things have been evolving.Are you around over the next couple of weeks to meet, either in person or via Zoom?</p> </br> <p>Best Regards,</p></br> <p>${props?.client_details?.advisor}</p>   <div style="max-width:560px; margin: 0 auto; border-top: 1px solid #ddd; padding: 20px;">
  <div style="float: left; width: 28%;">
    <p style="font-family:'Roboto', sans-serif;font-size:12px; padding: 0;color:#8A8A8A; text-align:left; font-size: 10px; margin: 0; padding: 0;">Powered by<br>
      <img style="width: 57px;" src="{{static_image_path}}/david-logo.png" />
    </p>
  </div>
  <div style="float: left;box-sizing: border-box;">
    <p style="font-family:'Roboto', sans-serif;font-size:12px; padding: 0;color:#8A8A8A; text-align:left; font-size: 11px; margin: 10px 0 0 0; padding: 0;">
      View in Browser | Privacy Policy</p>
  </div>
  <div style="clear: both;"></div>
</div>`;

  const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(defaultContent))))

  const [valueTo, setValueTo] = useState(props?.client_details?.email || "");
  const [valueCc, setValueCc] = useState("");
  const [valueBcc, setValueBcc] = useState("");
  const [valueSubject, setValueSubject] = useState("Catch Up");
  const [errors, setErrors] = useState({});
  const [isZoomCreating,setIsZoomCreating]= useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const getZoomMeetingLink=async ()=>{
    try {
      setIsZoomCreating(true)
      const response=await axiosGet("create-zoom-room",{},true)
      const respData=response.data
      const joinurl=respData.join_url
         setZoomMeetingLink(joinurl)
         setIsZoomCreating(false)
         return joinurl
    }
    catch{
      setIsZoomCreating(false)
    }
}
const isZoomLinkPresent = () => {
  const content = editorState.getCurrentContent();
  const blocks = content.getBlockMap().toArray();
  return blocks.some(block => block.getText().includes('Zoom link'));
};
  const insertHTMLInNewLineAtIndex = (editorState, htmlContent, lineIndex) => {
    const contentState = editorState.getCurrentContent();
    const blockArray = contentState.getBlockMap().toArray();
    // Parse the HTML content into Draft.js blocks
    const parsedBlocks = convertFromHTML(htmlContent);
    const newBlockMap = ContentState.createFromBlockArray(
      parsedBlocks.contentBlocks,
      parsedBlocks.entityMap
    ).getBlockMap();
    // Insert the empty block at the specified index
    const updatedBlockArray = [
      ...blockArray.slice(0, lineIndex),
      ...newBlockMap.toArray(),
      ...blockArray.slice(lineIndex),
    ];
  
    // Create a new ContentState with the updated block array
    const updatedContentState = ContentState.createFromBlockArray(updatedBlockArray);
  
    // Push the updated content state to the EditorState
    return EditorState.push(editorState, updatedContentState, 'insert-fragment');
  };
  const handleRemoveZoomLink = () => {
    const content = editorState.getCurrentContent();
    const blocks = content.getBlockMap().toArray();

    // Find the block containing the Zoom link
    const blockToRemove = blocks.find(block => {
      const blockText = block.getText();
      return blockText.includes('Zoom link');
    });

    if (blockToRemove) {
      const blockKey = blockToRemove.getKey();

      // Create a selection that spans the entire block
      const selection = SelectionState.createEmpty(blockKey).merge({
        anchorOffset: 0,
        focusOffset: blockToRemove.getLength(),
      });

      // Use Modifier.removeRange to delete the block's content
      const updatedContentState = Modifier.removeRange(content, selection, 'backward');

      // Now we remove any empty blocks (blocks with an empty string "")
      const newBlocksArray = updatedContentState.getBlockMap().toArray().filter(block => block.getText().trim() !== "");

      // Recreate the ContentState without the empty blocks
      const newContentState = ContentState.createFromBlockArray(newBlocksArray);

      // Push the updated content state to the editor state
      const newEditorState = EditorState.push(editorState, newContentState, 'remove-range');
      setEditorState(newEditorState);
    }
  };
  const handleInsertZoomLinkAtLine = async () => {
    let zoomLink;
    if(zoomMeetingLink==""){
        zoomLink=await getZoomMeetingLink() ;
    }
       
    const zoomLinkText = `
      <div class="public-DraftStyleDefault-block public-DraftStyleDefault-ltr">
        <span class="rdw-link-decorator-wrapper">
          Zoom link:<a href=${zoomLink || zoomMeetingLink} target="_self">
            <span data-text="true">${zoomLink || zoomMeetingLink}</span>
          </a>
        </span>
      </div>
    `;
    const lineIndex = 3; // Line 5
    const updatedEditorState = insertHTMLInNewLineAtIndex(editorState, zoomLinkText, lineIndex);
    setEditorState(updatedEditorState);
  };
  const handleToggleZoomLink = () => {
    if (isZoomLinkPresent()) {
      handleRemoveZoomLink();
    } else {
      handleInsertZoomLinkAtLine();
    }
  };

 var EmailBody = (`<!DOCTYPE html>\r\n<html>\r\n<head>\r\n<title>Page Title</title>\r\n</head>\r\n<body><div>${draftToHtml(convertToRaw(editorState.getCurrentContent()))}</div></body></html>`)
  const validateForm = () => {
    const newErrors = {};

    // Validate the "To" field
    if (!valueTo) {
      newErrors.valueTo = "To field is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(valueTo)) {
      newErrors.valueTo = "Invalid email address";
    }

    // Validate the "Subject" field
    if (!valueSubject) {
      newErrors.valueSubject = "Subject is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const submitMatch = async (id, cc, bcc, to) => {
    if (!validateForm()) return;
    let url = "sendclientmailreport"
    const formData = new FormData()
    const mailObj = {
      client_id: props?.client_details?.client_id,
      message_sender: [currentUserEmail],
      message_to: to,
      message_body: EmailBody,
      message_cc: cc,
      message_bcc: bcc,
      message_subject: valueSubject
    };

    await axiosPost('SendClientMailProfile/', mailObj)
      .then((res) => {
        if (res.status) {
          props.afterSuccessfullySent();
          enqueueSnackbar('Mail sent successfully.', { variant: 'success',anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          }, });
        } else {
          enqueueSnackbar(res.message, { variant: 'error',anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          }, });
        }
      })
      .catch(() => {
        enqueueSnackbar("Mail has not been send. Please check the mail id(s) or contact Administator.", { variant: 'error' ,anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },});
      });
  };

  return (
    <div className="mail-modal-body">

<div className="mail-modal-bottom">
              <button
               type="button"
                onClick={props.close}
                className="matchCancelButton btn"
              >
                DISCARD
              </button>
              <button
                type="button"
                onClick={() => {
                  var to = valueTo.split(";")
                  var cc = valueCc.split(";")
                  var bcc = valueBcc.split(";")
                  var loopTO = to ? to.filter(w => w.length > 0) : []
                  var loopCC = cc ? cc.filter(w => w.length > 0) : []
                  var loopBCC = bcc ? bcc.filter(w => w.length > 0) : []
                  submitMatch(props?.clientIDMail, loopCC, loopBCC, loopTO)
      
                }}
      
                className="matchSubmitButton btn"
              >
                SEND
              </button>
            </div>
            <div style={{ height: '94%', overflowX: 'hidden', overflowY: 'auto', width: '100%', paddingTop: '20px' }}>
      <Formik
        initialValues={{ valueTo, valueCc, valueBcc, valueSubject }}
        onSubmit={() => submitMatch()}
      >
        {() => (
          
          <Form noValidate>
            
            <Grid container spacing={2}>
              <Grid item xs={12} md={10}>
                <TextField
                  label="To"
                  fullWidth
                  value={valueTo}
                  onChange={(e) => setValueTo(e.target.value)}
                  error={!!errors.valueTo}
                  helperText={errors.valueTo}
                />
              </Grid>

              <Grid item xs={12} md={10}>
                <TextField
                  label="CC"
                  fullWidth
                  value={valueCc}
                  onChange={(e) => setValueCc(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={10}>
                <TextField
                  label="BCC"
                  fullWidth
                  value={valueBcc}
                  onChange={(e) => setValueBcc(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={10}>
                <TextField
                  label="Subject"
                  fullWidth
                  value={valueSubject}
                  onChange={(e) => setValueSubject(e.target.value)}
                  error={!!errors.valueSubject}
                  helperText={errors.valueSubject}
                />
              </Grid>
              <Grid item xs={12} md={10}>
              <Button
                          width="170px"
                          bgColor="secondary"
                          disabled={isZoomCreating}
                          onClick={(e) => {
                            handleToggleZoomLink()
                            }}
                          className="insertZoomlink btn"
                        >
                          {isZoomLinkPresent() ? 'Remove Zoom Link' : 'Attach Zoom Link'} {isZoomCreating&&<CircularProgress size={12} style={{ color: "#32C2D9",marginLeft:"3px" }}/>}
                        </Button>
              </Grid>
            </Grid>

            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={(editorState) => setEditorState(editorState)}
            />

          </Form>
        )}
      </Formik>
      </div>
    </div>
  );
}

export default DashbaordMailView;

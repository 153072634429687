import { AUTH_ACTION_TYPES } from '../redux/auth/auth.types';
import { axiosPost, axiosGet, handleOnError, handleOnSuccess, handleActionStart, axiosPut } from '../utils/api';
import { history } from '../App';
import appConfig from '../appConfig.json';
import { addLogoutMessage} from '../redux/auth/auth.action';



export const signInUserService = async (dispatch, singInData) => {
    try
    {
        dispatch(handleActionStart(AUTH_ACTION_TYPES.SIGN_IN_USER_START));
        const loginDetails = await axiosPost('login/', singInData, '', false);
        dispatch(handleOnSuccess(loginDetails, AUTH_ACTION_TYPES.SIGN_IN_USER_SUCCESS));
    }
    catch (err)
    {
        dispatch(handleOnError(err.response, AUTH_ACTION_TYPES.SIGN_IN_USER_FAILURE, true));
    }
}


export const logOutUserService = async (dispatch) => {
    try
    {
        dispatch(handleActionStart(AUTH_ACTION_TYPES.LOG_OUT_USER_START));
        const logoutDetails = await axiosGet('logout/');
       
       
        dispatch(handleOnSuccess(logoutDetails, AUTH_ACTION_TYPES.LOG_OUT_USER_SUCCESS));
    }
    catch (err)
    {
        dispatch(handleOnError(err.response, AUTH_ACTION_TYPES.LOG_OUT_USER_FAILURE));
    }
}

/* 
    Used in case of session expire popup case
*/
export const logOutWithOutLoginPageRedirectionService = async (dispatch) => {
    try {
        dispatch(handleActionStart(AUTH_ACTION_TYPES.LOG_OUT_WITH_OUT_LOGIN_PAGE_REDIRECTION_START));
        const data = await axiosGet('logout/');
        dispatch(handleOnSuccess(data, AUTH_ACTION_TYPES.LOG_OUT_WITH_OUT_LOGIN_PAGE_REDIRECTION_SUCCESS));
    }
    catch(err) {
        dispatch(handleOnError(err.response,AUTH_ACTION_TYPES.LOG_OUT_WITH_OUT_LOGIN_PAGE_REDIRECTION_FAILURE));
    }
}





export const resetPasswordService = async (dispatch, resetPasswordData) => {
    try
    {
        dispatch(handleActionStart(AUTH_ACTION_TYPES.RESET_PASSWORD_START));
        const resetPasswordDetails = await axiosPost('reset-password/', resetPasswordData);
        dispatch(handleOnSuccess(resetPasswordDetails, AUTH_ACTION_TYPES.RESET_PASSWORD_SUCCESS));
    }
    catch (err)
    {
        dispatch(handleOnError(err.response, AUTH_ACTION_TYPES.RESET_PASSWORD_FAILURE));
    }
}


/* 
    Service to send forgot password mail
*/
export const sendForgotPasswordMailService = async (dispatch, userData) => {
    try
    {
        dispatch(handleActionStart(AUTH_ACTION_TYPES.SEND_FORGOT_PASSWORD_MAIL_START));
        const resetPasswordDetails = await axiosPost('forgot-password/', userData, '', false);
        dispatch(handleOnSuccess(resetPasswordDetails, AUTH_ACTION_TYPES.SEND_FORGOT_PASSWORD_MAIL_SUCCESS));
    }
    catch (err)
    {
        dispatch(handleOnError(err.response, AUTH_ACTION_TYPES.SEND_FORGOT_PASSWORD_MAIL_FAILURE));
    }
}



/* 
    Non api logout service with out token 
    eg: used when token is invalid and user needs to be logged out immediately
*/
export const logOutUserWithoutTokenService = async (dispatch) => {
    dispatch(addLogoutMessage({logoutSnackBarMessage:appConfig.messages.session_error_message,logoutSnackBarType:"error"}));
    dispatch(handleActionStart(AUTH_ACTION_TYPES.LOG_OUT_USER_SUCCESS));

}





/*
    Service to set notification count
*/
export const setNotificationCountService = async (dispatch) => {
    try {
        dispatch(handleActionStart(AUTH_ACTION_TYPES.SET_NOTIFICATION_COUNT_START));
        const data = await axiosGet(`reminder/notification-count/`);
        dispatch(handleOnSuccess(data, AUTH_ACTION_TYPES.SET_NOTIFICATION_COUNT_SUCCESS));
    }
    catch(err) {
        dispatch(handleOnError(err.response,AUTH_ACTION_TYPES.SET_NOTIFICATION_COUNT_FAILURE));
    }
}




import { MAIL_ACTION_TYPES } from './mail.types';
import { mailIConOrderIntegrator, getUnreadMailsIdArray, expandAllCollapseAllConvoBoxToggler, getInboxUnreadCount } from './mail.utils';
import { arrayToRandomObjectsTransformer, isObjectEmpty, combineResult, removeDeletedMail, markReadMail } from '../../utils/utils';





const INITIAL_STATE = {

    mailFolderList: null,
    mailFolderListLoading: false,
    mailFolderListError: null,


    mailsInFolderLoading: false,
    mailsInFolder: null,
    mailsInFolderError: null,
    unreadMailsIdArray: [],
    mailsInFolderCount: 0,
    mailsInFolderOffset: 0,
    mailsInFolderId: null,

    mailDataListLoading: false,
    mailDataList: null,
    mailDataListError: null,

    isSearchMailReceiptLoading: false,
    searchMailRecipientsData: [],
    searchMailRecipientsErrorData: null,

    sendMailReplayLoading: false,
    sendMailReplayErrorData: null,
    sendMailReplayData: null,


    mailCategoriesLoading: false,
    mailCategoriesData: null,
    mailCategoriesError: null,



    templatesLoading: false,
    templatesData: null,
    templatesError: null,

    composeMailLoading: false,
    composeMailData: null,
    composeMailError: null,

    isInternalDriveFoldersFetching: false,
    internalDriveFoldersData: null,
    internalDriveFoldersErrorMessage: null,

    expandedItems: {},

    isMarkMailAsReadLoading: false,
    markMailAsReadErrorMessage: null,
    markMailAsReadSuccess: null,


    moveMailToFolderFetching: false,
    moveMailToFolderSuccessMessage: null,
    moveMailToFolderErrorMessage: null,

    editOutlookSettingsLoading: false,
    editOutlookSettingsSuccessMsg: null,
    editOutlookSettingsErrorMsg: null,
    editOutlookSettingsStatus:false,



    saveOutlookSettingsLoading: false,
    saveOutlookSettingsSuccessMsg: null,
    saveOutlookSettingsErrorMsg: null,
    saveOutlookSettingsErrorStatus: false,

    fetchOutlookSettingsLoading: false,
    fetchOutlookSettingsSuccessMsg: null,
    fetchOutlookSettingsErrorMsg: null,
    outlookSettingsData : null
}



const mailReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type)
    {
        case MAIL_ACTION_TYPES.GET_MAIL_FOLDER_LIST_START:
            return {
                ...state,
                mailFolderListLoading: true,
            }
        case MAIL_ACTION_TYPES.GET_MAIL_FOLDER_LIST_SUCCESS:
            return {
                ...state,
                mailFolderListLoading: false,
                mailFolderList: mailIConOrderIntegrator(payload.data),
                inboxUnreadCount: getInboxUnreadCount(payload.data)
            }
        case MAIL_ACTION_TYPES.GET_MAIL_FOLDER_LIST_FAILURE:
            return {
                ...state,
                mailFolderListLoading: false,
                mailFolderListError: payload.message
            }




        /* 
            List of mails from folder, eg list of mails in inbox
        */
        case MAIL_ACTION_TYPES.GET_MAILS_FROM_FOLDER_START:
            return {
                ...state,
                mailsInFolderLoading: true,
            }
        case MAIL_ACTION_TYPES.GET_MAILS_FROM_FOLDER_SUCCESS:
            return {
                ...state,
                mailsInFolderLoading: false,
                mailsInFolder: combineResult(state.mailsInFolder, payload, state.mailsInFolderId),
                unreadMailsIdArray: getUnreadMailsIdArray(combineResult(state.mailsInFolder, payload, state.mailsInFolderId)),
                mailsInFolderCount:payload?.count,
                mailsInFolderOffset:payload?.offset,
                mailsInFolderId: payload?.folder ? parseInt(payload?.folder) : null
            }
        case MAIL_ACTION_TYPES.GET_MAILS_FROM_FOLDER_FAILURE:
            return {
                ...state,
                mailsInFolderLoading: false,
                mailsInFolderError: payload.message === 'Validation error' ? null : payload.message, // message null if validation error else this will also show up
            }

        case MAIL_ACTION_TYPES.REMOVE_MAIL_ID_FROM_ARRAY:
            return {
                ...state,
                unreadMailsIdArray: state.unreadMailsIdArray.filter(item => item !== payload)
            }




        /* 
            Mail details will be list of conversations in parent mail
        */
        case MAIL_ACTION_TYPES.GET_MAIL_DETAILS_START:
            return {
                ...state,
                mailDataListLoading: true,
            }
        case MAIL_ACTION_TYPES.GET_MAIL_DETAILS_SUCCESS:
            return {
                ...state,
                mailDataListLoading: false,
                mailDataList: payload.data
            }
        case MAIL_ACTION_TYPES.GET_MAIL_DETAILS_FAILURE:
            return {
                ...state,
                mailDataListLoading: false,
                mailDataListError: payload.message === 'Validation error' ? null : payload.message, // message null if validation error else this will also show up
            }

        // Remove mail details
        case MAIL_ACTION_TYPES.REMOVE_MAIL_DETAILS:
            return {
                ...state,
                mailDataList: null
            }

        case MAIL_ACTION_TYPES.SEARCH_MAIL_RECIPIENTS_START:
            return {
                ...state,
                isSearchMailReceiptLoading: true,
            }
        case MAIL_ACTION_TYPES.SEARCH_MAIL_RECIPIENTS_SUCCESS:


            return {
                ...state,
                isSearchMailReceiptLoading: false,
                searchMailRecipientsData: arrayToRandomObjectsTransformer(payload.data, 'searchRecipientName')
            }
        case MAIL_ACTION_TYPES.SEARCH_MAIL_RECIPIENTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                searchMailRecipientsErrorData: payload.message
            }




        case MAIL_ACTION_TYPES.SEND_MAIL_REPLAY_START:
            return {
                ...state,
                sendMailReplayLoading: true,
            }
        case MAIL_ACTION_TYPES.SEND_MAIL_REPLAY_SUCCESS:
            return {
                ...state,
                sendMailReplayLoading: false,
                sendMailReplayData: payload.message
            }
        case MAIL_ACTION_TYPES.SEND_MAIL_REPLAY_FAILURE:
            return {
                ...state,
                sendMailReplayLoading: false,
                sendMailReplayErrorData: payload.message
            }


        case MAIL_ACTION_TYPES.CLEAR_MAIL_REPLAY_ERROR_MESSAGE:
            return {
                ...state,
                sendMailReplayErrorData: null
            }

        case MAIL_ACTION_TYPES.CLEAR_MAIL_REPLAY_SUCCESS_MESSAGE:
            return {
                ...state,
                sendMailReplayData: null
            }





        case MAIL_ACTION_TYPES.COMPOSE_MAIL_START:
            return {
                ...state,
                composeMailLoading: true,
            }
        case MAIL_ACTION_TYPES.COMPOSE_MAIL_SUCCESS:
            return {
                ...state,
                composeMailLoading: false,
                composeMailData: payload.message
            }
        case MAIL_ACTION_TYPES.COMPOSE_MAIL_FAILURE:
            return {
                ...state,
                composeMailLoading: false,
                composeMailError: payload.message
            }

        case MAIL_ACTION_TYPES.REMOVE_COMPOSE_MAIL_SUCCESS_MESSAGE:
            return {
                ...state,
                composeMailData: null
            }

        case MAIL_ACTION_TYPES.REMOVE_COMPOSE_MAIL_ERROR_MESSAGE:
            return {
                ...state,
                composeMailError: null
            }




        case MAIL_ACTION_TYPES.FETCH_MAIL_CATEGORIES_START:
            return {
                ...state,
                mailCategoriesLoading: true,
            }
        case MAIL_ACTION_TYPES.FETCH_MAIL_CATEGORIES_SUCCESS:
            return {
                ...state,
                mailCategoriesLoading: false,
                mailCategoriesData: payload.data
            }
        case MAIL_ACTION_TYPES.FETCH_MAIL_CATEGORIES_FAILURE:
            return {
                ...state,
                mailCategoriesLoading: false,
                mailCategoriesError: payload.message
            }

        case MAIL_ACTION_TYPES.REMOVE_MAIL_CATEGORIES_ERROR_MESSAGE:
            return {
                ...state,
                mailCategoriesError: null
            }



        case MAIL_ACTION_TYPES.FETCH_MAIL_TEMPLATES_START:
            return {
                ...state,
                templatesLoading: true,
            }
        case MAIL_ACTION_TYPES.FETCH_MAIL_TEMPLATES_SUCCESS:
            return {
                ...state,
                templatesLoading: false,
                templatesData: payload.data
            }
        case MAIL_ACTION_TYPES.FETCH_MAIL_TEMPLATES_FAILURE:
            return {
                ...state,
                templatesLoading: false,
                templatesError: payload.message
            }

        case 'CLEAR_TEMPLATE_DATA':
            return {
                ...state,
                templatesData: [],
            }




        case MAIL_ACTION_TYPES.GET_INTERNAL_DRIVE_FOLDERS_START:
            return {
                ...state,
                isInternalDriveFoldersFetching: true,
            }
        case MAIL_ACTION_TYPES.GET_INTERNAL_DRIVE_FOLDERS_SUCCESS:
            return {
                ...state,
                internalDriveFoldersData: payload.data,
                isInternalDriveFoldersFetching: false,
            }

        case MAIL_ACTION_TYPES.GET_INTERNAL_DRIVE_FOLDERS_FAILURE:
            return {
                ...state,
                isLoading: false,
                internalDriveFoldersErrorMessage: payload.message,
                isInternalDriveFoldersFetching: false,
            }



        case MAIL_ACTION_TYPES.MARK_MAIL_AS_READ_START:
            return {
                ...state,
                isMarkMailAsReadLoading: true,
            }
        case MAIL_ACTION_TYPES.MARK_MAIL_AS_READ_SUCCESS:
            return {
                ...state,
                isMarkMailAsReadLoading: false,
                markMailAsReadSuccess: payload.message,
                inboxUnreadCount: getInboxUnreadCount(payload.data)  // undating count at unread
            }
        case MAIL_ACTION_TYPES.MARK_MAIL_AS_READ_FAILURE:
            return {
                ...state,
                isMarkMailAsReadLoading: false,
                markMailAsReadErrorMessage: payload.message
            }



        case MAIL_ACTION_TYPES.MOVE_MAIL_TO_FOLDER_START:
            return {
                ...state,
                moveMailToFolderFetching: true,
            }
        case MAIL_ACTION_TYPES.MOVE_MAIL_TO_FOLDER_SUCCESS:
            return {
                ...state,
                moveMailToFolderFetching: false,
                moveMailToFolderSuccessMessage: payload.message,
            }
        case MAIL_ACTION_TYPES.MOVE_MAIL_TO_FOLDER_FAILURE:
            return {
                ...state,
                moveMailToFolderFetching: false,
                moveMailToFolderErrorMessage: payload.message
            }


        case MAIL_ACTION_TYPES.SET_EMAIL_TEMPLATE_HEADER:
            return {
                ...state,
                emailTemplateHeader: payload
            }
        case MAIL_ACTION_TYPES.SET_EMAIL_TEMPLATE_FOOTER:
            return {
                ...state,
                emailTemplateFooter: payload
            }
        case MAIL_ACTION_TYPES.SET_EMAIL_TEMPLATE_CONTENT:
            return {
                ...state,
                emailTemplateContent: payload
            }





        case MAIL_ACTION_TYPES.EXPAND_COLLAPSE_MAIL_REPLAY_BOX:
            return {
                ...state,
                expandedItems: {
                    ...state.expandedItems,
                    [payload]: !state.expandedItems[payload]
                }
            }

        case MAIL_ACTION_TYPES.EXPAND_ALL_MAIL_REPLAY_BOX:
            return {
                ...state,
                expandedItems: payload
            }



        case MAIL_ACTION_TYPES.FETCH_OUTLOOK_SETTINGS_START:
            return {
                ...state,
                fetchOutlookSettingsLoading: true,
            }
        case MAIL_ACTION_TYPES.FETCH_OUTLOOK_SETTINGS_SUCCESS:
            return {
                ...state,
                fetchOutlookSettingsSuccessMsg: false,
                fetchOutlookSettingsLoading: false,
                outlookSettingsData : payload.data[0]
            }
        case MAIL_ACTION_TYPES.FETCH_OUTLOOK_SETTINGS_FAILURE:
            return {
                ...state,
                fetchOutlookSettingsLoading: false,
                fetchOutlookSettingsErrorMsg: payload.message
            }



        case MAIL_ACTION_TYPES.SAVE_OUTLOOK_SETTINGS_START:
            return {
                ...state,
                saveOutlookSettingsLoading: true,
            }
        case MAIL_ACTION_TYPES.SAVE_OUTLOOK_SETTINGS_SUCCESS:
            return {
                ...state,
                saveOutlookSettingsLoading: false,
                saveOutlookSettingsSuccessMsg: payload.message,
                saveOutlookSettingsErrorStatus : payload.status
            }
        case MAIL_ACTION_TYPES.SAVE_OUTLOOK_SETTINGS_FAILURE:
            return {
                ...state,
                saveOutlookSettingsLoading: false,
                saveOutlookSettingsErrorMsg: payload.message
            }
        case MAIL_ACTION_TYPES.REMOVE_SAVE_OUTLOOK_SETTINGS_ERROR_MSG:
            return {
                ...state,
                saveOutlookSettingsErrorMsg: null
            }
        case MAIL_ACTION_TYPES.REMOVE_SAVE_OUTLOOK_SETTINGS_SUCCESS_MSG:
            return {
                ...state,
                saveOutlookSettingsSuccessMsg: null
            }




        case MAIL_ACTION_TYPES.EDIT_OUTLOOK_SETTINGS_START:
            return {
                ...state,
                editOutlookSettingsLoading: true,
                editOutlookSettingsStatus: false,
            }
        case MAIL_ACTION_TYPES.EDIT_OUTLOOK_SETTINGS_SUCCESS:
            return {
                ...state,
                editOutlookSettingsLoading: false,
                editOutlookSettingsSuccessMsg: payload.message,
                editOutlookSettingsStatus : payload.status
            }
        case MAIL_ACTION_TYPES.EDIT_OUTLOOK_SETTINGS_FAILURE:
            return {
                ...state,
                editOutlookSettingsLoading: false,
                editOutlookSettingsErrorMsg: payload.data.detail
            }
        case MAIL_ACTION_TYPES.REMOVE_EDIT_OUTLOOK_SETTINGS_ERROR_MSG:
            return {
                ...state,
                editOutlookSettingsErrorMsg: null
            }

        case MAIL_ACTION_TYPES.REMOVE_DELETED_MAIL_FROM_LIST:
            return {
                ...state,
                mailsInFolder: removeDeletedMail(state.mailsInFolder, payload),
                unreadMailsIdArray: getUnreadMailsIdArray(removeDeletedMail(state.mailsInFolder, payload)),
            }

        case MAIL_ACTION_TYPES.MARK_READ_MAIL_FROM_LIST:
            return {
                ...state,
                mailsInFolder: markReadMail(state.mailsInFolder, payload),
                unreadMailsIdArray: getUnreadMailsIdArray(markReadMail(state.mailsInFolder, payload)),
            }

        // Remove mails in folder while folder switching
        case MAIL_ACTION_TYPES.REMOVE_MAILS_IN_FOLDER:
            return {
                ...state,
                mailsInFolder: null,
                unreadMailsIdArray: [],
                mailsInFolderCount: 0,
                mailsInFolderOffset: 0,
                mailsInFolderId: null
            }


        default:
            return state;
    }
}


export default mailReducer;


